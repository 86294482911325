import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { DebtResp, Debt } from '../../Model/debt.model';

// Carga de token y añadido a header

@Injectable({
  providedIn: 'root',
})
export class EconomicService {

  constructor(private ds: DataService) { }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  header = {
    token: ''
  };

  // Obtiene las deudas de las cuotas de Hermano
  getPendingDebt() {
    return new Promise<Debt[]>((resolve) => {
      this.ds.getData('ph/economic/debts').then((resp: DebtResp) => {
        if (resp.ok) {
          resolve(resp.debts);
        }
      });
    });
  }

  getRedsysData() {
    return new Promise<any>(resolve => {
      this.ds.getData('administration/config/redsysParams').then(resp => resolve(resp));
    });
  }

  async generateOrder(idDebt: number) {
    return new Promise<any>(resolve => {
      this.ds.getData('redsysPay/order/' + idDebt).then(resp => {
        resolve(resp);
      });
    });
  }

  async setPaymentRedsys(idDebt: number, idOper: any, order: string) {
    return new Promise<any>(resolve => {
      this.ds.setData('redsysPay/init/' + idDebt, { idOper, order }).then(resp => {
        resolve(resp);
      });
    });
  }

  async redsysPay(idDebt: number, idOper: any, order: string, _3ds: string) {
    return new Promise<any>(resolve => {
      this.ds.setData('redsysPay/pay/' + idDebt, { idOper, order, _3ds }).then(resp => {
        resolve(resp);
      });
    });

  }
  async generateOrderTest(idDebt: number) {
    return new Promise<any>(resolve => {
      this.ds.getData('redsysPay/orderTest/' + idDebt).then(resp => {
        resolve(resp);
      });
    });
  }

  async setPaymentRedsysTest(idDebt: number, idOper: any, order: string) {
    return new Promise<any>(resolve => {
      this.ds.setData('redsysPay/initTest/' + idDebt, { idOper, order }).then(resp => {
        resolve(resp);
      });
    });
  }

  async redsysPayTest(idDebt: number, idOper: any, order: string, _3ds: string, navigatorData?: any) {
    return new Promise<any>(resolve => {
      this.ds.setData('redsysPay/payTest/' + idDebt, { idOper, order, _3ds, navigatorData }).then(resp => {
        resolve(resp);
      });
    });

  }



}
