import { Injectable } from '@angular/core';
import { globalUser, brotherhoodData } from '../../environments/globales';
import { DataService } from './data.service';

import { GlobalServiceService } from './global-service.service';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class LoginService {

  header = {
    token: ''
  };

  constructor(private ds: DataService,
              private router: Router,
              private gs: GlobalServiceService) { }

  login(email: string, password: string) {
    return new Promise<any>(resolve => {
      this.ds.setData('intranet/login', { email, password }).then(async resp => {
        // Si la respuesta contiene ok: true --> Almacenamos el token
        if (resp.ok) {
          await this.ds.saveStorege('tokenApp', resp.token);
          await this.ds.saveStorege('username', email);
          await this.ds.saveStorege('password', password);
          const login = await this.checkToken();
          if (!login.ok) {
            this.ds.delStorage('tokenApp');
            return resolve(login);
          }
          // Almaceno los datos en enviroment globals
          globalUser.id = login.user.id;
          globalUser.identifierName = login.user.nombre;
          globalUser.email = login.user.email;
          return resolve(login);
        }
        resolve(resp);
      });
    });
  }

  checkToken() {
    return new Promise<any>(resolve => {
      this.ds.getData('intranet/chek/token').then(async resp => {
        if (!resp.user) {
          const email: any = this.ds.readStorage('username');
          const password: any = this.ds.readStorage('password');
          if (!email.ok || !password.ok){
            return resolve({
              ok: false
            });
          }
          const login = await this.login(email.read, password.read);
          if (login.ok){
            globalUser.id = resp.user.id;
            globalUser.identifierName = resp.user.nombre;
            globalUser.email = resp.user.email;
            return resolve (login);
          } else {
            return resolve(false);
          }
        };
          globalUser.id = resp.user.id;
          globalUser.identifierName = resp.user.nombre;
          globalUser.email = resp.user.email;
          globalUser.brotherNumber = resp.user.brotherNumber;
        resolve(resp);
      });
    });
  }

  register(email: string, identifier: string) {
    return new Promise<any>(resolve => {
      this.ds.setData('intranet', { email, identifier }).then(resp => {
        resolve(resp);
      });
    });
  }

  logout() {
    //this.header.token = '';
    return new Promise<boolean>(resolve => {
      this.ds.delStorage('tokenApp');
      this.ds.delStorage('username');
      this.ds.delStorage('password');
      resolve(true);
    });
  }

  loadRedsysValues() {
    return new Promise<any>(resolve => {
      this.ds.getData('administration/config/redsysParams').then(resp => {
        if (resp.ok) {
          brotherhoodData.mode = resp.mode.value;
        }
      });
      resolve(true);
    });
  }

  restorePass(email: string){
    return new Promise<any>(resolve => {
      this.ds.setData('user/restorePass', {email}).then(resp => resolve(resp));
    });
  }

  changeTokenToAsociate(id: number) {
    return new Promise<any>(resolve => {
      this.ds.getData('intranet/changeToken/' + id).then(async resp => {
        if (resp.ok) {
          await this.ds.saveStorege('tokenApp', resp.token);
          // Cuando esta almacenado ... tenemos que verificarlo y decodificarlo
          const login = await this.checkToken();
          // Almaceno los datos en enviroment globals
          globalUser.id = login.user.id;
          globalUser.identifierName = login.user.nombre;
          globalUser.email = login.user.email;
          this.loadRedsysValues();
          return resolve(login);
        }
        resolve(resp);
      });
    });
  }

}
