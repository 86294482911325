import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitTo'
})
export class LimitToPipe implements PipeTransform {
  transform(value: any, args: string): string {
    const limit = args ? parseInt(args, 10) : 10;
    const trail = '...';
    return String(value).length > limit ? value.substring(0, limit) + trail : value;
  }
}
