import { Injectable } from '@angular/core';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private ds: DataService) { }

  getEvents() {
    return new Promise<any>((resolve) => {
      this.ds.getData('administration/events').then((resp) => {
        resolve(resp.eventsData[0]);
      });
    });
  }
}
