import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiService } from '../../services/ui.service';
import { brotherhoodData } from '../../../environments/globales';
import { BrotherDataService } from '../../services/portalServices/brother-data.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent implements OnInit {

  safetyCheck: string = '';
  password: string = '';
  hide: boolean = true;
  hideSafety: boolean = true;
  modalTitle = 'Cambiar contraseña';
  formPassword: FormGroup;
  local_data: any = {};

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private ui: UiService,
    private bds: BrotherDataService
  ) {
    this.formPassword = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(5)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(5)]]
    });
  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    const respBrotherData = await this.bds.getBrotherData();
    this.local_data = respBrotherData.brother;
  }

  back() {
    this.modalCtrl.dismiss();
  }

  toggleHide() {
    this.hide = !this.hide;
  }

  toggleHideSafety() {
    this.hideSafety = !this.hideSafety;
  }

  async doAction() {
    this.modalCtrl.dismiss({
      password: this.password
    });
  }
}
