import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ActionSheetController } from '@ionic/angular';
import { GaleryService } from '../../services/galery.service';
import { environment } from '../../../environments/environment.prod';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-galery-modal',
  templateUrl: './galery-modal.component.html',
  styleUrls: ['./galery-modal.component.scss'],
})
export class GaleryModalComponent implements OnInit {

  @Input()item: any = {};
  url = environment.backendUrl;
  galeryItems: any[] = [];
  constructor(private modalCtrl: ModalController,
             private gs: GaleryService,
             private iab: InAppBrowser) { }

  ngOnInit() {
    this.loadItems();
  }

  async loadItems(){
    this.galeryItems = await this.gs.getGaleryItems(this.item.id);
  }

  back(){
    this.showToggle();
    this.modalCtrl.dismiss();
  }

  openImg(img: any){
    const url = `${this.url}/galeryItem/item/${img.fileName}`;
   // eslint-disable-next-line max-len
   this.iab.create(url, '_blank', 'closebuttoncaption=Cerrar,footer=yes,footercolor=#622b75,location=no,presentationstyle=pagesheet');
  }


  showToggle() {
    const toggleId = document.querySelector('#toggleComponent');
    toggleId.classList.remove('hide-toggle');
    const tabMenu = document.querySelector('#tabPral');
    tabMenu.classList.remove('hide-menu');
  }

}
