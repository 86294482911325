import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../environments/environment.prod';
import { GaleryService } from '../../services/galery.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-titular-modal',
  templateUrl: './titular-modal.component.html',
  styleUrls: ['./titular-modal.component.scss']
})

export class TitularModalComponent implements OnInit {
  url = environment.backendUrl;
  galeryItems: any[] = [];
  noImg = false;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()t: any = {};

  slideOpts = {
    initialSlide: 0,
    speed: 400,
    mode: 'ios',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    }
  };

  constructor(private modalCtrl: ModalController, private gs: GaleryService, private iab: InAppBrowser) { }

  async ngOnInit() {

    if (this.t.idGaleryAlbum){
      this.galeryItems = await this.gs.getGaleryItems(this.t.idGaleryAlbum);
    }
  }

  back() {
    this.modalCtrl.dismiss();
    this.showToggle();
  }

  showToggle() {
    const toggleId = document.querySelector('#toggleComponent');
    toggleId.classList.remove('hide-toggle');
    const tabMenu = document.querySelector('#tabPral');
    tabMenu.classList.remove('hide-menu');
  }
  open(file: string){
    const url = `${this.url}/galeryItem/item/${file}`;
    this.iab.create(url, '_blank', 'closebuttoncaption=Cerrar,footer=yes,location=no,presentationstyle=pagesheet');
  }

}
