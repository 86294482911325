import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-read-correspondence',
  templateUrl: './read-correspondence.component.html',
  styleUrls: ['./read-correspondence.component.scss'],
})
export class ReadCorrespondenceComponent implements OnInit {
  @Input()data: any = {};
  constructor( private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  back(){
    this.modalCtrl.dismiss();
  }
}
