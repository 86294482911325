import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BrotherDataService } from '../../services/portalServices/brother-data.service';

@Component({
  selector: 'app-brother-data-modal',
  templateUrl: './brother-data-modal.component.html',
  styleUrls: ['./brother-data-modal.component.scss'],
})
export class BrotherDataModalComponent implements OnInit {

  local_data: any = { address: {} };
  modalTitle = 'Datos de Hermano/a'
  constructor(
    private modalCtrl: ModalController,
    private bds: BrotherDataService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    const respBrotherData = await this.bds.getBrotherData();
    this.local_data = respBrotherData.brother;
  }

  back() {
    this.modalCtrl.dismiss();
  }

}
