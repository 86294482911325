import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment.prod';
import { brotherhoodData, appData } from '../environments/globales';

const URL = environment.backendUrl;

@Injectable({
  providedIn: 'root'
})


export class ApiConfig {


  constructor(private http: HttpClient) { }

  async loadBasicData() {
    return this.http.get(URL + '/administration/config/simpliData').toPromise().then((resp: any) => {
      if (resp.ok) {
        resp.data.forEach((e: any) => {

          if (e.name === 'simpliBrotherhoodName') {
            brotherhoodData.SimpliBrotherhoodName = e.value;
          }
          if (e.name === 'heraldryImg') {
            brotherhoodData.heraldryImg = `${URL}/administration/config/img/${e.value}`;
          }
          if (e.name === 'address') {
            brotherhoodData.address = e.value;
          }
          if (e.name === 'localtion') {
            brotherhoodData.localtion = e.value;
          }
          if (e.name === 'province') {
            brotherhoodData.province = e.value;
          }
          if (e.name === 'postalCode') {
            brotherhoodData.postalCode = e.value;
          }
          if (e.name === 'email') {
            brotherhoodData.email = e.value;
          }
          if (e.name === 'telephone') {
            brotherhoodData.telephone = e.value;
          }
          if(e.name === 'newBrotherFee') {
            appData.newBrotherFee = Number(e.value); // --> Mayor de edad
          }
          if(e.name === 'newBrotherFeeYounger') {
            appData.newBrotherFeeYounger = Number(e.value);  // --> Menor de edad
          }
          if (e.name === 'intranetTerms'){
            brotherhoodData.intranetTerms = e.value;
          }
          if (e.name === 'youtubeChanel'){
            brotherhoodData.youtubeChanel = e.value;
          }
          if (e.name === 'procesionMode') {
            brotherhoodData.procesionMode = e.value;
          }
          if(e.name === 'maxHeritageRequests') {
            brotherhoodData.maxHeritageRequests = e.value;
          }
          if(e.name === 'newBrotherFee'){
            appData.newBrotherFee = Number(e.value); // --> Mayor de edad
        }
          if(e.name === 'newBrotherFeeYounger') {
            appData.newBrotherFeeYounger = Number(e.value); // --> Menor de edad
          }
          if (e.name === 'newBrotherFeeConcept') {
            appData.newBrotherConcept = e.value;
          }
        });
      }
      return Promise.resolve();
    });
  }

  async loadAppData() {
    return this.http.get(URL + '/administration/config/appData').toPromise().then((resp: any) => {
      if (resp.ok) {
        resp.data.forEach((e: any) => {
          if (e.name === 'initText') {
            appData.initText = e.value;
          }
          if (e.name === 'historyText') {
            appData.historyText = e.value;
          }
          if (e.name === 'groupText') {
            appData.groupText = e.value;
          }
          if (e.name === 'governingText') {
            appData.governingText = e.value;
          }
          if (e.name === 'rulesText') {
            appData.rulesText = e.value;
          }
          if (e.name === 'socialCareText') {
            appData.socialCareText= e.value;
          }
          if (e.name === 'donationText') {
            appData.donationText = e.value;
          }
          if (e.name === 'seatsText') {
            appData.seatsText = e.value;
          }
        });
      }
      return Promise.resolve();
    });
  }

  async loadRedsysData(){
    return this.http.get(URL + '/administration/config/redsysParams').toPromise().then((resp: any) => {
      if (resp.ok){
        brotherhoodData.mode = Number(resp.mode.value);
      }
      return Promise.resolve();
    });
  }
}
