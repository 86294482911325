/* eslint-disable @typescript-eslint/naming-convention */
import { User } from '../app/Model/user.model';

export const globalUser: User = {};
export const dataPuch: any = '';

/**
 *  Leera los datos del modelo de configuración no creado aun
 */

export const brotherhoodData = {
  procesionMode: 'Paso', // TRONO o PASO
  SimpliBrotherhoodName: 'null',
  brotherhoodName: '',
  heraldryImg: 'null',
  infocafImg: 'assets/images/infocaf.png',
  maxHeritageRequests: 6,
  intranetEnabled: false,
  appEnabled: false,
  archiveEnabled: false,
  brotherhoodIdentifier: '',
  address: '',
  localtion: '',
  province: '',
  postalCode: '',
  email: '',
  telephone: '',
  mode: 2,
  intranetTerms: '',
  youtubeChanel: '',
};

export const appData = {
  initText: '',
  historyText: '',
  groupText: '',
  governingText: '',
  rulesText: '',
  socialCareText: '',
  donationText: '',
  seatsText: '',
  newBrotherFee: 0,
  newBrotherFeeYounger: 0,
  newBrotherConcept: ''
};

export const platformRun = {
  heightPlatform: undefined,
  ios: false,
  android: true
};

export const toggleVar = {
  enable: false
};



