import { Injectable } from '@angular/core';
import { PlacementTicket, PlacementTicketResp } from 'src/app/Model/placementTicket.model';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root'
})
export class PlacementTicketService {

  constructor(private ds: DataService) { }

  setPlacementTicket(pts: PlacementTicket){
    return new Promise<PlacementTicketResp>(resolve => {
      this.ds.setData('ph/placementTicket', pts).then(resp => {
        resolve(resp);
      });
    });
  }
}
