import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenderPipe } from './gender.pipe';
import { MaritalStatusPipe } from './maritalStatus.pipe';
import { LimitToPipe } from './limit-to.pipe';
import { BooleanPipe } from './boolean.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [
      GenderPipe,
      MaritalStatusPipe,
      LimitToPipe,
      BooleanPipe,
      DateFormatPipe
    ],
    imports: [
      CommonModule,
      IonicModule
    ],
    exports: [
      GenderPipe,
      MaritalStatusPipe,
      LimitToPipe,
      BooleanPipe,
      DateFormatPipe
    ]
  })
  
  export class PipesModule { }
