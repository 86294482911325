import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { BrotherResp, BrotherPaymentMethodResp } from '../../Model/brother.model';
import { globalUser } from '../../../environments/globales';

@Injectable({
  providedIn: 'root'
})
export class BrotherDataService {

  constructor(private ds: DataService) { }

  // Trae los datos del Hermano
  getBrotherData() {
    return new Promise<BrotherResp>((resolve) => {
      const ID = globalUser.id;
      this.ds.getData('ph/brother/get').then((resp) => {
        resolve(resp);
      });
    });
  }

  // Actualiza los datos del Hermano
  updateDataBrother(brother: any) {
    return new Promise<any>((resolve) => {
      this.ds.updateData('ph/brother/update', brother).then((resp) => {
        resolve(resp);
      });
    });
  }

  // Obtiene el método de pago de un hermano por la id de brother
  getBrotherPaymentMethod() {
    return new Promise<BrotherPaymentMethodResp>((resolve) => {
      const ID = globalUser.id;
      this.ds.getData(`ph/economic/bpm`).then((resp) => {
        resolve(resp);
      });
    });
  }

  // Permite establecer el método de Pago
  setBrotherPaymentMethod(brotherPM?: any) {
    return new Promise<BrotherPaymentMethodResp>((resolve) => {
      this.ds.setData(`ph/economic/bpm`, brotherPM).then((resp) => {
        resolve(resp);
      });
    });
  }

  // Permite eliminar el método de Pago
  delBrotherPaymentMethod() {
    return new Promise<BrotherPaymentMethodResp>((resolve) => {
      const ID = globalUser.id;
      this.ds.delData(`brother/paymentMethod/${ID}`).then((resp) => {
        resolve(resp);
      });
    });
  }

  //Actualiza las deudas con el nuevo método de pago del hermano / colaborador
  changeBrotherPMDebt(idNewBPM: any) {
    return new Promise<BrotherPaymentMethodResp>((resolve) => {
      this.ds.updateData(`debt/changebpm/${idNewBPM}`, {}).then((resp) => {
        resolve(resp);
      });
    });
  }

  //Obtiene las relaciones del Hermano
  getRelation() {
    return new Promise<any>((resolve) => {
      this.ds.getData('ph/relation/relationship').then((resp) => {
        resolve(resp);
      });
    });
  }

  //Obtiene los grupos a los que pertenece el Hermano
  getGroup() {
    return new Promise<any>((resolve) => {
      this.ds.getData('ph/relation/groups').then((resp) => {
        resolve(resp);
      });
    });
  }

  // Obtiene el Modelo 182
  getModel182() {
    return new Promise<any>((resolve) => {
      this.ds.getData('ph/economic/182').then((resp) => {
        resolve(resp.model182records);
      });
    });
  }

  // Obtiene el tipo de Pago a través del ID del Hermano
  getFeeByBrotherId() {
    return new Promise<any>((resolve) => {
      this.ds.getData('ph/economic/fee/brother').then(resp => {
        resolve(resp);
      });
    });
  }

  // Obtiene Marital Status
  getMaritalStatus() {
    return new Promise<any>(resolve => {
      this.ds.getData('comun/maritalstatus').then(resp => resolve(resp));
    });
  }

  // Obtiene los datos de localidad a través del CP.
  getGeodata(cp: string) {
    return new Promise<any>(resolve => {
      this.ds.getData(`comun/geodata?cp=${cp}`).then(resp => resolve(resp));
    });
  }

  // Obtiene los períodos de pago
  getPaymentPeriods() {
    return new Promise<any>(resolve => {
      this.ds.getData('economic/paymentperiod/intranet').then(resp => resolve(resp));
    });
  }

  getRelationPortal() {
    return new Promise<any>((resolve) => {
      this.ds.getData('ph/relation/portal').then((resp) => {
        resolve(resp);
      });
    });
  }


  // Envía la Solicitud para nuevo Hermano:

  async setBrotherApplication(form: any) {
    const formData: FormData = new FormData();
    formData.append('doc1', form.doc1);
    formData.append('doc2', form.doc2);
    formData.append('name', form.brother.name);
    formData.append('firstName', form.brother.firstName);
    formData.append('lastName', form.brother.lastName);
    formData.append('identifier', form.brother.identifier);
    formData.append('idMaritalStatus', form.brother.idMaritalStatus);
    formData.append('email', form.brother.email);
    formData.append('birthDate', form.brother.birthDate);
    formData.append('telephone1', form.brother.telephone1);
    formData.append('gender', form.brother.gender);
    formData.append('baptismDate', form.brother.baptismDate);
    formData.append('parish', form.brother.parish);
    formData.append('newsletter', form.brother.newsletter);
    formData.append('baptizedIn', form.brother.baptizedIn);
    formData.append('agreeLopdPTS', form.brother.agreeLopdPTS);
    formData.append('address', form.brother.address);
    formData.append('cp', form.brother.cp);
    formData.append('location', form.brother.location);
    formData.append('province', form.brother.province);
    formData.append('ca', form.brother.ca);
    formData.append('country', form.brother.country);
    formData.append('iban', form.paymentMethod.iban);
    formData.append('idPaymentMethod', form.paymentMethod.idPaymentMethod);
    formData.append('idPaymentPeriod', form.paymentMethod.idPaymentPeriod);
    formData.append('pay', form.payData);
    if (form.paymentMethod.accountOwner !== 0) {
      formData.append('ownerDni', form.paymentMethod.ownerDni);
    }
    if (form.paymentMethod.accountOwner === 1) {
      formData.append('bankOwnerName', form.paymentMethod.bankOwnerName);
      formData.append('bankOwnerFirstName', form.paymentMethod.bankOwnerFirstName);
      // formData.append('bankOwnerLastName', form.paymentMethod.bankOwnerLastName); //Preparar en el front, NO OBLIGATORIO
      formData.append('ownerAddress', form.paymentMethod.ownerAddress);
      formData.append('ownerEmail', form.paymentMethod.ownerEmail);
      formData.append('ownerTelephone', form.paymentMethod.ownerTelephone);
      formData.append('cpOwner', form.paymentMethod.cpOwner);
    }

    return new Promise<any>((resolve) => {
      this.ds.setData('brother/brotherApplication', formData).then(resp => resolve(resp));
    });
  }

  unsuscribeBrother(reason: any){
    return new Promise<any>(resolve => {
      this.ds.setData('intranet/unsuscribeBrotherProtal', reason).then(resp => resolve(resp));
    });
  }
}
