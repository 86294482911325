import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalServiceService } from '../../services/global-service.service';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent  implements OnInit{


  @Input()name = 'Inicio';
  @Input()back = '/';
  @Input()backActive = false;
  @Input()active = false;
  customColor = '--white';
  toggleLeftText = 'La Hermandad';
  toggleRightText = 'Mi Portal';

  constructor(private router: Router, private gs: GlobalServiceService) { }

  ngOnInit(){
    this.activeToggle();
  }

  backControll(){
    this.router.navigate([this.back]);
  }

  activeToggle() {
    if (this.active){
      this.toggleHermandad();
    } else {
      this.togglePortal();
    }
  }

  async togglePortal() {
    this.active = !this.active;
    const toggle = document.querySelector('.switch');
    const brotherHoodActive: any = document.querySelector('#brotherHood');
    const brothersPortalActive: any = document.querySelector('#brothersPortal');
    const toggleBall: any = document.querySelector('.switch-ball');

    brotherHoodActive.style.backgroundColor = 'var(--background-toggle)';
    brotherHoodActive.style.boxShadow = 'none';
    brothersPortalActive.style.backgroundColor = 'var(--white)';
    brothersPortalActive.style.boxShadow = 'inset 0px 0px 8px  rgba(0, 0, 0, 0.25)';
    toggleBall.style.background = 'var(--firstcolor) var(--toggle-icon-brotherhood)';
    toggleBall.style.transform = 'translateX(0px)';
    this.gs.publishSomeData({ togglePublic: true });
    await this.router.navigate(['/']);
  }
  async toggleHermandad() {
    this.active = !this.active;
    const toggle = document.querySelector('.switch');
    const brotherHoodActive: any = document.querySelector('#brotherHood');
    const brothersPortalActive: any = document.querySelector('#brothersPortal');
    const toggleBall: any = document.querySelector('.switch-ball');

    brotherHoodActive.style.backgroundColor = 'var(--white)';
    brotherHoodActive.style.boxShadow = 'inset 0px 0px 8px  rgba(0, 0, 0, 0.25)';
    brothersPortalActive.style.backgroundColor = 'var(--background-toggle)';
    brothersPortalActive.style.boxShadow = 'none';
    toggleBall.style.transform = 'translateX(42px)';
    toggleBall.style.background = 'var(--firstcolor) var(--toggle-icon-brothersportal)';
    this.gs.publishSomeData({ togglePublic: false });
    // await this.router.navigate(['/portal-tabs']);
  }
}
