import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { brotherhoodData } from '../../../environments/globales';

@Component({
  selector: 'app-legal-modal',
  templateUrl: './legal-modal.component.html',
  styleUrls: ['./legal-modal.component.scss'],
})
export class LegalModalComponent implements OnInit {

  lopd = false;
  rgpd = brotherhoodData.intranetTerms;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}


  onClickAceptar() {
    this.modalCtrl.dismiss({
      lopd: true
    });
  }

  onClickRechazar() {
    this.modalCtrl.dismiss({
      lopd: false
    });
  }
}
