import { Component, OnInit, Input } from '@angular/core';
import { ModalController, PickerController } from '@ionic/angular';
import { BrotherDataService } from '../../services/portalServices/brother-data.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-update-data-modal',
  templateUrl: './update-data-modal.component.html',
  styleUrls: ['./update-data-modal.component.scss'],
})
export class UpdateDataModalComponent implements OnInit {

  brother: any = {};
  local_data: any = { address: {} };
  maritalStatus: any = [];
  editAddress: boolean = false;
  multiAddress: any[] = [];
  initCP: boolean = false;


  constructor(
    private modalCtrl: ModalController,
    private bds: BrotherDataService,
    private pickerCtrl: PickerController,
    private ui: UiService
  ) { }

  async ngOnInit() {
    this.loadData();
  }

  async loadData() {
    const respBrotherData = await this.bds.getBrotherData();
    const maritalStatusResp = await this.bds.getMaritalStatus();
    this.local_data = respBrotherData.brother;
    this.maritalStatus = maritalStatusResp;
  }

  back() {
    this.modalCtrl.dismiss();
  }

  /**
   * Función que permite a Ionic conocer el tipo de dato.
   * initCP en false al inicio para que no lance el evento nada más abrir el modal, 
   * solo cuando existan cambios
   * @param event 
   * @returns event.target.value
   */
  async onChangeHandler(event: Event) {
    if (!this.initCP) return this.initCP = true;
    else {
      const eventTarget: any = (event as CustomEvent).target;
      await this.setGeoData(eventTarget.value);
    }
  }

  async setGeoData(event: string) {
    this.multiAddress = [];
    if (event.length == 5) {
      const data: any = await this.bds.getGeodata(event);
      if (!data.ok) {
        this.editAddress = true;
        this.local_data.address.country = '';
        this.local_data.address.ca = '';
        this.local_data.address.province = '';
        this.local_data.address.location = '';
        return;
      } else {
        if (data.data.length > 1) {
          this.multiAddress = data.data;
          this.local_data.address.country = data.data[0].country.name;
          this.local_data.address.ca = data.data[0].geodata_1.name;
          this.local_data.address.province = data.data[0].geodata_2.name;
          this.local_data.address.location = data.data[0].name;
          this.editAddress = false;
        } else {
          this.local_data.address.country = data.data[0].country.name;
          this.local_data.address.ca = data.data[0].geodata_1.name;
          this.local_data.address.province = data.data[0].geodata_2.name;
          this.local_data.address.location = data.data[0].name;
          this.editAddress = false;
        }
      }
    } else {
      this.editAddress = true;
      this.local_data.address.country = '';
      this.local_data.address.ca = '';
      this.local_data.address.province = '';
      this.local_data.address.location = '';
    }
  }

  async openPicker() {
    const picker = await this.pickerCtrl.create({
      columns: [
        {
          name: 'maritalStatus',
          options: [
            {
              text: 'Casado/a',
              value: '1',
            },
            {
              text: 'Soltero/a',
              value: '2',
            },
            {
              text: 'Separado/a',
              value: '3',
            },
            {
              text: 'Viudo/a',
              value: '4',
            },
          ],
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        },
        {
          text: 'Confirmar',
          handler: (value) => {
            this.local_data.idMaritalStatus = Number(value.maritalStatus.value);
            this.ui.alertInfo(`Has elegido: ${value.maritalStatus.text}`, '');
          },
        },
      ],
    });

    await picker.present();
  }

  async updateDataBrother() {
    const updateData = await this.bds.updateDataBrother(this.local_data);
    if(!updateData.ok) {return window.alert(updateData.error);}
    this.ui.alertInfo('Datos actualizados correctamente', '');
    this.modalCtrl.dismiss();
  }

}
