import { Injectable } from '@angular/core';
import { Procesion } from 'src/app/portal-tabs/procesion/model/procesion.model';
import { PlacementTicketResp } from '../../Model/placementTicket.model';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root'
})
export class ProcesionService {

  constructor(private ds: DataService) { }


  getProcesionHistory() {
    return new Promise<PlacementTicketResp> (resolve => {
      this.ds.getData('ph/procesion/history').then(resp => resolve(resp));
    });
  }

  getProcesion() {
    return new Promise<Procesion> (resolve => {
      this.ds.getData('ph/procesion/enabled').then(resp => resolve(resp));
    });
  }

  loadHeritagesInProcesion(id: any){
    return new Promise<any>(resolve => {
      this.ds.getData('ph/hipProcesion/' + id).then(resp => {
        resolve(resp);
      });
    });
  }

  loadPasosInProcesion(idProcesion: any) {
    return new Promise<any>(resolve => {
      this.ds.getData('ph/pasosInProcesion/' + idProcesion).then(resp => {
        resolve(resp);
      })
    })
  }

  getBrothersApplicationData(idProcesion: any) {
    return new Promise<any>(resolve => {
      this.ds.getData('ph/currentPtsData/' + idProcesion).then(resp => {
        resolve(resp);
      })
    })
  }

  setHeritageRequest(hr: any) {
    return new Promise<any>(resolve => {
      this.ds.setData('ph/hipProcesion', hr).then(resp => {
        resolve(resp);
      });
    });
  }

  getLastPts(idProcesion: number) {
    return new Promise<any> (resolve => {
      this.ds.getData('ph/lastProcesion/' + idProcesion).then(resp => resolve(resp));
    });
  }

  getHeritagesSimpleAssignment(id: any){
    return new Promise<any>(resolve => {
      this.ds.getData('ph/gethsa/' + id).then(resp => {
        resolve(resp);
      });
    });
  }

  getPasosInProcesion(idProcesion: any) {
    return new Promise<any>(resolve => {
      this.ds.getData('ph/pasosInProcesion/' + idProcesion).then(resp => {
        resolve(resp);
      });
    });
  }

  sentdCurentPts(idProcesion: any){
    return new Promise<any>(resolve => {
      this.ds.getData('printer/mailpts/' + idProcesion).then(resp => {
        resolve(resp);
      });
    });
  }

  getConfigHr(idProcesion: any){
    return new Promise<any>(resolve => {
      this.ds.getData('ph/getConfigHr/' + idProcesion).then(resp => resolve(resp));
    });
  }
}
