import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class GaleryService {

  constructor(private ds: DataService) { }

  getGaleryAlbums(){
    return new Promise<any>(resolve => {
      this.ds.getData('galeryAlbum').then(resp => {
        if (resp.ok){
          resolve(resp.ga);
        }
      });
    });
  }

  getGaleryItems(idAlbum: number){
    return new Promise<any>(resolve => {
      this.ds.getData('galeryItem/' + idAlbum).then(resp => {
        if (resp.ok){
          resolve(resp.gis);
        }
      });
    });
  }
}
