import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private ds: DataService) { }

  getNotifications(){
    return new Promise<any>(resolve =>{
      this.ds.getData('notification/brotherNotification').then(resp => {
        if (resp.ok){
          resolve(resp.notifications);
        }
      });
    });
  }
  getNotificationId(id: number){
    return new Promise<any>(resolve =>{
      this.ds.getData('notification/brotherNotification/' +id).then(resp => {
        if (resp.ok){
          resolve(resp.notification);
        }
      });
    });
  }


}
