import { Component } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { LoginComponent } from '../../modals/login/login.component';
import { Router } from '@angular/router';
import { GlobalServiceService } from '../../services/global-service.service';
import { WeddingModalComponent } from '../../modals/wedding-modal/wedding-modal.component';
import { EventsService } from '../../services/portalServices/events.service';

@Component({
  selector: 'app-tabs-portal',
  templateUrl: './tabs-portal.component.html',
  styleUrls: ['./tabs-portal.component.scss'],
})

export class TabsPortalComponent implements ViewWillEnter {
  existEvent = false;
  // data: any = {};

  constructor(private loginService: LoginService,
              private router: Router,
              private gs: GlobalServiceService,
              private modalCtrl: ModalController,
              private es: EventsService
            ) {
              this.gs.getObservable().subscribe(r => {
                if (r.existEvent){
                  this.existEvent = true
                }
              });
             }

  async ionViewWillEnter() {
    
    const resp = await this.loginService.checkToken();
    if (!resp.ok) {
      const modal = await this.modalCtrl.create({
        component: LoginComponent,
        backdropDismiss: false,
        cssClass: 'titularsModal',
        mode: 'ios'
      });
      await modal.present();
    } 
  }


  open(route: string){
    this.router.navigate([route]);
  }

  async loadEvent(){
    let today = new Date();
    const eventResp: any = await this.es.getEvents();
    if (!eventResp){
      return;
    }
    
    if (eventResp && (new Date(eventResp.endDate).getTime() >= today.getTime())) {
      this.openWeddingModal(eventResp);
    }
}

  async openWeddingModal(dataModal: any) {
    const modal = await this.modalCtrl.create({
      component: WeddingModalComponent,
      componentProps: {data: dataModal },
      backdropDismiss: false,
      cssClass: 'modal-weeding',
      mode: 'ios'
    })
    
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      this.gs.publishSomeData({ existEvent: true });
    }
  }

  displayMenu(){
    const navbar = document.querySelector('.navbar');
    const btnIcon = document.querySelector('.displayMenuBtn');
    const secondaryMenu = document.querySelector('.secondary-menu-container');
    const footerImg = document.querySelector('#footerImg');

    btnIcon.classList.toggle('btnRotate');
    btnIcon.classList.toggle('btnPosition');
    navbar.classList.toggle('menu-visible');
    footerImg.classList.toggle('img-top');
    footerImg.classList.toggle('up-top');

    if (secondaryMenu) {
      secondaryMenu.classList.toggle('menu-left');
    }
  }
}
