/* eslint-disable max-len */

import { Injectable } from '@angular/core';
import OneSignal from 'onesignal-cordova-plugin';
import { DataService } from './data.service';
import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(private ds: DataService, private ptf: Platform) { }

  async iniciarPush() {
    // eslint-disable-next-line max-len
    console.log('DATALOG',Device.model);
    this.ds.getData('administration/config/oneSignal', 'GhercofInogaAppPayClod2021200$%hermandadesYcofrades2007¢%&/11198210404$$·').then(resp => {

      console.log('DATALOG', JSON.stringify(resp));
      if (resp.ok) {
        console.log('DATALOG', 'INICIA');
        OneSignal.setAppId(resp.pushKey);
        OneSignal.setNotificationOpenedHandler((jsonData) => {
          console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
        });

        // Prompts the user for notification permissions.
        //* Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
        OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
          console.log('User accepted notifications: ' + accepted);
        });
      }
    });
  }

  saveAppDevice() {
    return new Promise<any>(resolve => {
      OneSignal.setExternalUserId(Device.uuid);
      this.ds.setData('brother/appdevice', {model: Device.model, tokenId: Device.uuid}).then(resp => {
        resolve(resp);
      });
    });
  }

}
