/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Component, Inject, Input, OnInit, Optional, Renderer2, OnDestroy, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment.prod';
import { EconomicService } from '../../services/portalServices/economic.service';
import { IonButton, ModalController, ViewDidEnter } from '@ionic/angular';
import { Form, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-authenticate-modal',
  templateUrl: './authenticate-modal.component.html',
  styleUrls: ['./authenticate-modal.component.scss']
})
export class AuthenticateModalComponent implements OnInit, OnDestroy {
  @Input()data: any = {};
  @ViewChild('btn')btn: IonButton;
  url = '/redsysPay/vreception/';
  location = environment.backendUrl;
  acsURL: any;
  termUrl: any;
  PAReq: any;
  MD: any;
  creq: any;
  clic = false;

  constructor(
    private economicService: EconomicService,
    private _renderer2: Renderer2,
    private modalCtrl: ModalController,
    @Inject(DOCUMENT) private _documentAuth: Document,
    ) {
      this.economicService.getRedsysData().then(resp => {
        if (resp.ok) {
          if (resp.mode.value !== '1') {
            this.url = '/redsysPayTest/vreception/';
          }
        }
      });
    }

    onClick(f: any){
      f.submit();
      this.clic = true;
    }

    ngOnInit() {

    this.acsURL = this.data.acsURL;
    this.termUrl = this.location + this.url + this.data.idOrder;
    this.PAReq = this.data.PAReq;
    this.MD = this.data.MD;
    this.creq = this.data.creq;



    // const script3 = this._renderer2.createElement('script');
    // script3.type = 'application/javascript';
    // elementAuth.setAttribute('method', 'POST');
    // elementAuth.setAttribute('action', this.data.acsURL);
    // elementAuth.setAttribute('target', 'iframe_3DS_Challenge');
    // if (this.data.PAReq){
    //   const Datos_PAReq = this._documentAuth.createElement('input');
    //   Datos_PAReq.setAttribute('type', 'hidden');
    //   Datos_PAReq.setAttribute('name', 'PaReq');
    //   Datos_PAReq.setAttribute('value', String(this.data.PAReq));
    //   elementAuth.appendChild(Datos_PAReq);

    //   // eslint-disable-next-line prefer-const
    //   let Datos_MD = this._documentAuth.createElement('input');
    //   Datos_MD.setAttribute('type', 'hidden');
    //   Datos_MD.setAttribute('name', 'MD');
    //   Datos_MD.setAttribute('value', this.data.MD);
    //   elementAuth.appendChild(Datos_MD);

    //   const TermUrl = this._documentAuth.createElement('input');
    //   TermUrl.setAttribute('type', 'hidden');
    //   TermUrl.setAttribute('name', 'TermUrl');
    //   TermUrl.setAttribute('value',  this.location + this.url + this.data.idOrder);
    //   elementAuth.appendChild(TermUrl);
    // }

    // if (this.data.creq){
    //     const Datos_Creq = this._documentAuth.createElement('input');
    //     Datos_Creq.setAttribute('type', 'hidden');
    //     Datos_Creq.setAttribute('name', 'CReq');
    //     Datos_Creq.setAttribute('value', String(this.data.creq));
    //     elementAuth.appendChild(Datos_Creq);
    // }

    // document.body.appendChild(elementAuth);
    // elementAuth.submit();
  }

  ngOnDestroy(): void {
    this.modalCtrl.dismiss();
  }

  close(){
    this.modalCtrl.dismiss();
  }

}
