/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IonList, ModalController } from '@ionic/angular';
import { brotherhoodData, globalUser } from '../../../environments/globales';
import { BrotherDataService } from '../../services/portalServices/brother-data.service';

import { UiService } from '../../services/ui.service';
import { AddNewBadgeModalComponent } from '../add-new-badge-modal/add-new-badge-modal.component';
import { PlacementTicketService } from '../../services/portalServices/placement-ticket.service';
import { ProcesionService } from '../../services/portalServices/procesion.service';
import { SelectPtsmodeComponent } from '../select-ptsmode/select-ptsmode.component';
import { RedsysService } from '../../services/portalServices/redsys.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import * as uuid from 'uuid';
import { environment } from '../../../environments/environment.prod';
import { FormGroup, FormBuilder, UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiConfig } from '../../apiConfig';

@Component({
  selector: 'app-pts-modal',
  templateUrl: './pts-modal.component.html',
  styleUrls: ['./pts-modal.component.scss'],
})
export class PtsModalComponent implements OnInit {

  @ViewChild('badgesList') badgesList: IonList;
  @Input() data: any = {};
  badges = [];
  local_data: any = {};
  local_pts: any = {};
  lopd = false;
  firstForm: FormGroup;
  secondForm: FormGroup;
  maxHeritageRequest = brotherhoodData.maxHeritageRequests;
  url = environment.backendUrl;
  full = false;
  hasEmail = false;
  ptsMode: number | undefined;
  pasosList: any[] = [];
  heritageList: any[] = [];
  idPasoInProcesion: any;
  idProcesion: any;
  sortMode: any;
  simpleAssignment = false;
  badgesSelectedList: any[] = [];
  lopdRequest = [
    { value: true, viewValue: 'Sí' },
    { value: false, viewValue: 'No' }
  ];
  submit = 'Confirmar y enviar';
  newPlacementTicket = {};
  ifHasCurrentPTS: any = {};
  ifHasLastPTS: any = {};
  currentPTSview = false;
  currentHRview = false;
  lastProcesionBadge = {};
  canSendNewHr = false;
  currentCandle: any = {};
  currentHipAccepted: any = {};
  needPay = false;
  amount = 0;
  win: any;
  interval: any;
  intervalEnd: any;
  process = false;
  end = false;
  real = true;
  opneFrom = '/redsysPay/openform/';

  constructor(
    private modalCtrl: ModalController,
    private bds: BrotherDataService,
    private fb: UntypedFormBuilder,
    private ps: ProcesionService,
    private ticket: PlacementTicketService,
    private ui: UiService,
    private rs: RedsysService,
    private iab: InAppBrowser,
    private apiConfig: ApiConfig
  ) {

    // Primer Formulario - Papeleta de Sitio:
    this.firstForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      telephone1: ['', [Validators.required, Validators.minLength(9)]],
      amountFlowers: [''],
      lopd: [true],
    });

    // Segundo Formulario - Solicitud Insignias
    this.secondForm = this.fb.group({
      priority: [1, Validators.required],
      idPasoInProcesion: ['', Validators.required],
      idHeritageInProcesion: ['', Validators.required]
    });
  }

  async ngOnInit() {
    await this.apiConfig.loadRedsysData();
    this.url = this.url.replace('/api', '');

    if (brotherhoodData.mode !== 1) {
      this.real = false;
      this.opneFrom = '/redsysPay/openformTest/';
    }

    this.loadData();

    if (this.data) {
      this.local_pts = this.data;
      this.ifHasCurrentPTS = this.data.respCurrentPTS;
      this.ifHasLastPTS = this.data.respLastPts;
    }

    // Controlar ptsMode
    if (this.local_pts.procesion.toDateIntranet && !this.local_pts.procesion.endOnlineBookingDate) {
      this.ptsMode = 0; // Solo heritage
    } else if (this.local_pts.procesion.endOnlineBookingDate && !this.local_pts.procesion.toDateIntranet) {
      this.ptsMode = 1; // Solo papeleta
    } else if (this.local_pts.procesion.toDateIntranet && this.local_pts.procesion.endOnlineBookingDate) {
      this.ptsMode = 2; // mixto
    }

    // Controlar si el hermano ya tiene papeleta - Controlar si ya ha hecho la solicitud de Insignias
    if (this.ifHasCurrentPTS && this.ifHasCurrentPTS.pts?.id) {
      this.currentPTSview = true;
    }

    let hipObject = {};

    if (this.ifHasCurrentPTS.hr.length > 0) {
      if (!this.ifHasCurrentPTS.hipAccepted) {
        for await (const hr of this.ifHasCurrentPTS.hr) {
          hipObject = {
            priority: hr.priority,
            idHeritageInProcesion: hr.idHeritageInProcesion,
            name: hr.heritageInProcesion?.name,
            vpa: hr.vpa,
            ipa: hr.ipa
          };

          if (hr.priority > Number(this.maxHeritageRequest)) {
            this.currentCandle = hr;
            if (this.ptsMode !== 2) {
              this.badgesSelectedList.push(hipObject);
            }
          } else {
            this.badgesSelectedList.push(hipObject);
          }
        }

        if (this.badgesSelectedList.length >= Number(this.maxHeritageRequest)) {
          this.canSendNewHr = false;
          this.currentHRview = true;
        } else {
          if (this.ptsMode !== 1 || (this.ptsMode === 1 && !this.currentCandle.idHeritageInProcesion)) {
            this.canSendNewHr = true;
            this.currentHRview = false;
          }
        }
      } else {
        this.canSendNewHr = false;
        this.currentHRview = true;
        hipObject = {
          priority: this.ifHasCurrentPTS.hr[0].priority,
          idHeritageInProcesion: this.ifHasCurrentPTS.hr[0].idHeritageInProcesion,
          name: this.ifHasCurrentPTS.hr[0].heritageInProcesion?.name,
          vpa: this.ifHasCurrentPTS.hr[0].vpa,
          ipa: this.ifHasCurrentPTS.hr[0].ipa,
          accepted: true
        };

        this.amount += Number(this.ifHasCurrentPTS.hr[0].heritageInProcesion?.fee) +
          Number(this.ifHasCurrentPTS.hr[0].heritageInProcesion?.procesionRole?.fee) +
          Number(this.ifHasCurrentPTS.hr[0].pasoInProcesion?.feePts);

        this.amount === 0 ? this.needPay = false : this.needPay = true;

        this.badgesSelectedList.push(hipObject);
        this.currentHipAccepted = hipObject;
      }

    }

    if (!this.ifHasCurrentPTS.pts?.id) {
      if (this.ifHasCurrentPTS.hr.length === 0) {
        if (this.ptsMode === 2) {
          const modal = await this.modalCtrl.create({
            component: SelectPtsmodeComponent,
            cssClass: 'example-modal badge-modal',
            backdropDismiss: false,
            mode: 'ios'
          });
          await modal.present();
          const { data } = await modal.onDidDismiss();
          if (data) {
            this.ptsMode = data.ptsMode;
          }
        }
        if (this.ifHasLastPTS && this.ifHasLastPTS.applicationPts?.heritageInProcesion) {
          const heritage = this.data.respLastPts.applicationPts.heritageInProcesion;

          this.lastProcesionBadge = {
            priority: 1,
            name: heritage.name,
            idHeritageInProcesion: heritage.id,
            simpleAssignment: heritage.simpleAssignment || false,
            idProcesion: heritage.idProcesion,
            idPasoInProcesion: heritage.idPasoInProcesion,
            idDirectoryEntry: heritage.idDirectoryEntry,
            ipa: heritage.ipa,
            vpa: heritage.vpa
          };

          this.badgesSelectedList.push(this.lastProcesionBadge);
          this.canSendNewHr = true;
        } else {
          this.canSendNewHr = true;
        }
      } else if (this.ifHasCurrentPTS.hr.length > 0) {
        if (this.ifHasCurrentPTS.hr.length < Number(this.maxHeritageRequest)) {
          if (this.ptsMode === 2) {
            const modal = await this.modalCtrl.create({
              component: SelectPtsmodeComponent,
              cssClass: 'example-modal badge-modal',
              backdropDismiss: false,
              mode: 'ios'
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            if (data) {
              this.ptsMode = data.ptsMode;
            }
          }
        } else {
          if (this.ptsMode === 2) {
            this.canSendNewHr = true;
            this.currentHRview = false;
            this.ptsMode = 1;
            this.badgesSelectedList = [];
          }
        }
      }
    }

    if (this.local_pts.procesion.dateProcesion) {
      this.currentPTSview = this.currentHRview = true;
    }

    //console.log(this.url + '/getQrProcesion/' + this.ifHasCurrentPTS.pts?.qrCode);
  }

  async loadData() {
    const brotherResp = await this.bds.getBrotherData();
    this.local_data = brotherResp.brother;

    if (!this.currentPTSview) {
      const pasosInResp = await this.ps.getPasosInProcesion(this.local_pts.procesion.id);
      if (!pasosInResp.ok) { return this.ui.alertError(pasosInResp.error); }
      this.pasosList = pasosInResp.pasosInProcesion;
    }
  }

  back() {
    this.modalCtrl.dismiss();
  }

  openQr() {
    const urlQr = this.url + '/getQrProcesion/' + this.ifHasCurrentPTS.pts?.qrCode;
    this.iab.create(urlQr, '_blank', 'closebuttoncaption=Cerrar,footer=yes,footercolor=#622b75,location=no,presentationstyle=pagesheet');
  }

  // BOTÓN SOLICITAR:
  async openBadgeModal() {

    // TODO: currentPtsData en Caso de que el hermano ya haya hecho la SOLICITUD
    // TODO: CONTROLAR getCurrentPTS y controlar getLastProcesion
    const modal = await this.modalCtrl.create({
      component: AddNewBadgeModalComponent,
      cssClass: 'example-modal badge-modal',
      backdropDismiss: false,
      mode: 'ios',
      componentProps: {
        data: this.local_pts,
        idDirectoryEntry: this.local_data.id,
        ptsMode: this.ptsMode
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data === 'close') {
      this.modalCtrl.dismiss();
    }
    if (data) {
      if (this.badgesSelectedList.some(x => (x.vpa && data.vpa) || (x.ipa && data.ipa))) {
        return this.ui.alertError('La insignia elegida ya ha sido solicitada. Pruebe de nuevo con otra opción.');
      }

      if (this.badgesSelectedList.some(x => x.idHeritageInProcesion === data.idHeritageInProcesion)) {
        return this.ui.alertError('La insignia elegida ya ha sido solicitada. Pruebe de nuevo con otra opción.');
      } else {
        if (this.ptsMode === 1) { data.priority = Number(this.maxHeritageRequest) + 1; }
        else { data.priority = this.badgesSelectedList.length + 1; }

        if ((Number(data.fee) > 0 || Number(data.feeYouger) > 0)) {
          this.amount += Number(data.fee);
        }
        this.badgesSelectedList.push(data);

        if (this.ptsMode === 1) {
          this.canSendNewHr = false;
        } else {
          if (this.badgesSelectedList.length >= Number(this.maxHeritageRequest)) {
            this.canSendNewHr = false;
          }
        }
      }
      this.amount === 0 ? this.needPay = false : this.needPay = true;
    }
  }

  // TODO: REVISAR EL CÓDIGO - NO TERMINA DE FUNCIONAR BIEN
  deleteBadge(b) {
    this.badgesList.closeSlidingItems();
    const index = this.badgesSelectedList.indexOf(b);

    this.badgesSelectedList.splice(index, 1);

    for (let i = 0; i < this.badgesSelectedList.length; i++) {
      this.badgesSelectedList[i].priority = i + 1;
    }

    this.canSendNewHr = true;
  }

  // Controlar los botones según el ptsMode
  isActive(): boolean {
    if (this.currentPTSview) { this.needPay = false; }

    if (!this.needPay) { this.submit = 'Confirmar y enviar'; }
    else { this.submit = 'Confirmar y pagar'; }

    switch (this.ptsMode) {
      case 0:
        if (this.badgesSelectedList.length > 0) { return true; }
        else { return false; }

      case 1:
        if (this.firstForm.value.telephone1 && this.firstForm.value.telephone1.length > 8 && this.firstForm.value.email
          && this.badgesSelectedList.some(hr => hr.idDirectoryEntry || hr.accepted)) { return true; }
        else { return false; }

      case 2:
        if (this.badgesSelectedList.length > 0 && ((this.firstForm.value.telephone1 && this.firstForm.value.telephone1.length > 8
          && this.firstForm.value.email) || this.currentPTSview)) { return true; }
        else { return false; }

      default:
        return false;
    }
  }

  onEmailBrother(event: any) {
    this.local_data.email = event;
  }

  onTelephoneBrother(event: any) {
    this.local_data.telephone1 = event;
  }

  onChangeAmountFlowers(event: any) {
    if (event && event.length > 0) {
      //this.submit = 'Confirmar y pagar';
      this.needPay = true;
    } else {
      if (this.amount <= 0) {this.needPay = false;}
    }
  }

  async confirmApplication() {
    if (this.firstForm.value.amountFlowers > 0) {
      this.needPay = true;
      this.amount += Number(this.firstForm.value.amountFlowers);
    }

    if (!this.needPay) {
      this.sendData();
      return;
    };
    if (this.ptsMode === 0) {
      this.sendData();
      return;
    }
    const date = new Date();
    const idTemp = uuid.v4();

    const ptsDebt = {
      idTemp,
      concept: 'PAPELETA DE SÍTIO ' + this.local_pts.procesion.name,
      amount: this.amount,
      reminderAmount: this.amount,
      debtDate: date,
      dueDate: date,
      payed: false,
      idPaymentMethod: 5,
      idDirectoryEntry: globalUser.id
    };

    const order = await this.rs.createOrderPayNoDebt(ptsDebt);
    if (!order.ok) {
      return window.alert('Error al generar la pasarela de pago');
    }
    const dataPay = {
      id: order.order.id,
      concept: 'PAPELETA DE SÍTIO ' + this.local_pts.procesion.name,
      amount: this.amount,
      reminderAmount: this.amount,
      debtDate: date,
      dueDate: date,
      payed: false,
      idPaymentMethod: 5,
    };

    this.openModal(dataPay);

  }

  async sendData(debtId?: any) {
    if (this.ptsMode !== 0 && !this.currentPTSview) {
      let dataObject: any = {};
      const idHeritageInProcesions = [];

      this.badgesSelectedList.forEach(d => {
        if (d.idDirectoryEntry) {
          if (!d.accepted) {
            idHeritageInProcesions.push(
              {
                id: d.idHeritageInProcesion,
                priority: d.priority,
                idProcesion: d.idProcesion,
                idPasoInProcesion: d.idPasoInProcesion,
                vpa: d.vpa,
                ipa: d.ipa,
                simpleAssignment: d.simpleAssignment,
              });
          } else {
            idHeritageInProcesions.push(
              {
                id: d.idHeritageInProcesion,
                priority: d.priority,
                accepted: d.accepted,
                simpleAssignment: d.simpleAssignment
              });
          }
        }
      });


      dataObject = {
        idDirectoryEntry: this.local_data.id,
        idProcesion: this.local_pts.procesion.id,
        amountFlowers: this.firstForm.value.amountFlowers || 0,
        agreeLgpd: this.firstForm.value.lopd,
        idOrder: debtId

      };

      if (idHeritageInProcesions.length > 0) { dataObject.idHeritageInProcesions = idHeritageInProcesions; }

      const resp = await this.ticket.setPlacementTicket(dataObject);
      if (!resp.ok) { return this.ui.alertError('La solicitud no se ha completado correctamente'); }
      await this.ps.sentdCurentPts(dataObject.idProcesion);
      this.modalCtrl.dismiss();
      this.ui.alertInfo('¡Solicitud de insignia realizada con éxito!', '');
      this.bds.updateDataBrother(this.local_data);

    } else {
      for await (const hr of this.badgesSelectedList) {
        if (hr.idDirectoryEntry) {
          if (hr.vpa || hr.ipa) { delete hr.idHeritageInProcesion; }
          const resp = await this.ps.setHeritageRequest(hr);
          if (!resp.ok) { return this.ui.alertError(resp.error); }
        }
      }
      await this.ps.sentdCurentPts(this.badgesSelectedList[0].idProcesion);
      this.modalCtrl.dismiss();
      this.ui.alertInfo('¡Solicitud de insignia realizada con éxito!', '');
    }
  }

  async sendConfirmMail() {
    const resp = await this.ps.sentdCurentPts(this.local_pts.procesion.id);
    if (!resp.ok) {return this.ui.alertError('Ha ocurrido un error inesperado durante el envío del email.');}
    this.ui.alertInfo(resp.message, '');
  }

  async openModal(debt: any) {

    this.win = this.iab.create(environment.backendUrl +
      this.opneFrom + debt.id,
      '_blank', 'location=no ,enableViewportScale=yes, closebuttoncaption=cerrar, zoom=yes, enableViewportScale=yes');

    this.win.on('exit').subscribe(() => {

    }, err => {
      console.error(err);
    });
    this.interval = setInterval(async () => {
      const resp = await this.rs.getOrderPayPts(debt.id);
      if (this.win?.closed) {
        clearInterval(this.interval);
      }
      if (resp.ok) {
        this.process = true;
        this.win?.close();
        clearInterval(this.interval);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const dataModal = { id: resp.order.idDebt, MD: resp.order.MD, order: resp.order.idOrder };

        if (this.real) {
          this.rs.setPaymentRedsys(dataModal.id, dataModal.MD, dataModal.order).then(async respPay => {
            switch (respPay.type) {
              case 1:
                this.rs.redsysPay(dataModal.id, dataModal.MD, dataModal.order, respPay.buff).then(async respPay1 => {
                  if (respPay1.ok && respPay1.respTrata.response === 0) {
                    this.sendData(debt.id);
                    this.modalCtrl.dismiss();
                    return;
                  } else if (respPay.ok && respPay1.respTrata.emv3ds) {
                    const dataResp = respPay1.respTrata.emv3ds;
                    dataResp.idOrder = dataModal.order;
                    await this.openAuth(dataResp);
                  }
                });
                break;
              case 2: // V: 2.1.0

                this.rs.redsysPay(dataModal.id, dataModal.MD, dataModal.order, respPay.buff).then(async respPay1 => {
                  if (respPay1.ok && respPay1.respTrata.response === 0) {
                    this.sendData(debt.id);
                    this.modalCtrl.dismiss();
                  } else if (respPay1.ok && respPay1.respTrata.emv3ds) {
                    const dataResp = respPay1.respTrata.emv3ds;
                    dataResp.idOrder = dataModal.order;
                    await this.openAuth(dataResp);
                  }
                });
                break;

              case 3:
                break;
            }

          });
        } else {
          this.rs.setPaymentRedsysTest(dataModal.id, dataModal.MD, dataModal.order).then(respPay => {
            switch (respPay.type) {
              case 1:
                this.rs.redsysPayTest(dataModal.id, dataModal.MD, dataModal.order, respPay.buff).then(async respPay1 => {
                  if (respPay1.ok && respPay1.respTrata.response === 0) {
                    this.sendData(debt.id);
                    this.modalCtrl.dismiss();
                  } else if (respPay.ok && respPay1.respTrata.emv3ds) {
                    const dataResp = respPay1.respTrata.emv3ds;
                    dataResp.idOrder = dataModal.order;
                    await this.openAuth(dataResp);
                  }
                });
                break;

              case 2: // V: 2.1.0
                const navigatorData = {
                  navigator: window.navigator.userAgent.toLowerCase(),
                  language: window.navigator.language,
                  heigth: window.screen.height.toString(),
                  width: window.screen.width.toString(),
                  colorDepth: window.screen.colorDepth.toString(),
                  timeZone: new Date().getTimezoneOffset()
                };

                this.rs.redsysPayTest(
                  dataModal.id, dataModal.MD, dataModal.order, respPay.buff, navigatorData).then(async respPay1 => {
                    if (respPay1.ok && respPay1.respTrata.response === 0) {
                      this.sendData(debt.id);
                      this.end = true;
                      this.modalCtrl.dismiss();
                    } else if (respPay.ok && respPay1.respTrata.emv3ds) {
                      const dataResp = respPay1.respTrata.emv3ds;
                      dataResp.idOrder = dataModal.order;
                      await this.openAuth(dataResp);
                    }
                  });
                break;
            }
          });
        }
      }
    }, 1000);
  }

  async openAuth(dataResp: any) {
    const html = `
  <html>
  <body>
  <form name="sendData" method="POST" action="${dataResp.acsURL}"
  target="iframe_3DS_Challenge" enctype="application/xwww-form-urlencoded" style="display: none">
      <input name="TermUrl" value="${dataResp.termUrl}">
      <input name="MD" value="${dataResp.MD}">
      <input name="CReq" value="${dataResp.creq}">
  </form>
  <iframe name="iframe_3DS_Challenge" src="" class="iframe_3DS_Challenge" style="height: 100%; width: 100%; border: 0"></iframe>
  </body>

  </html>

  <script type="text/javascript">
    window.onload = function() {
      document.forms["sendData"].submit();
    }
  </script>`;
    const pageContent = 'data:text/html;base64,' + btoa(html);
    const auth = this.iab.create(
      pageContent, '_blank', 'hidden=no,location=no,clearsessioncache=yes,clearcache=yes');

    auth.executeScript({ code: 'document.getElementById("boton").click();' }).then(() => {
    });
  }
}
