/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { GlobalServiceService } from '../../services/global-service.service';
import { ModalController, Platform } from '@ionic/angular';
import { LoginService } from '../../services/login.service';
import { UiService } from '../../services/ui.service';
import { PushService } from '../../services/push.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { NewBrotherModalComponent } from '../new-brother-modal/new-brother-modal.component';
import { RegisterComponent } from '../register/register.component';
import { EventsService } from 'src/app/services/portalServices/events.service';
import { LoadAsociateBrotherComponent } from './load-asociate-brother/load-asociate-brother.component';
import { BrotherDataService } from '../../services/portalServices/brother-data.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  email = '';
  password = '';
  open = true;
  @Input() existEvent = false;

  constructor(
    private pushService: PushService,
    private modalCtrl: ModalController,
    private gs: GlobalServiceService,
    private loginService: LoginService,
    private ds: DataService,
    private bs: BrotherDataService,
    private ui: UiService,
    private ptf: Platform,
    private es: EventsService
  ) { }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit(): void {
    const tabMenu = document.querySelector('#tabPortal');
    tabMenu.classList.add('hide-menu');
    this.gs.getObservable().subscribe((data) => {
      if (data.togglePublic !== undefined) {
        if (data.togglePublic) {
          console.log(this.open);
          if (this.open) {
            this.modalCtrl.dismiss();
          }
        }
      }
    });
  }

  async login() {
    const resp = await this.loginService.login(this.email, this.password);
    // Realiza el inicio de sesión y asigna el resultado a la variable login
    if (resp.ok) {
      await this.pushService.saveAppDevice();
      const respAsociate = await this.bs.getRelationPortal();
      if (respAsociate.ok) {
        if (respAsociate.brother.entityType !== 'brother') {
          if (respAsociate.directoryentryrelationship.length > 0) {
            const haveBrother = respAsociate.directoryentryrelationship.some((br: any) => br.brother.entityType === 'brother');
            if (!haveBrother) {
              this.ds.delStorage('tokenApp');
              return this.ui.alertError('Usted no consta como hermano/a, ni constan vinculaciones con hermanos/as. Póngase en contacto con la hermandad.');
            }
          } else {
            this.ds.delStorage('tokenApp');
            return this.ui.alertError('Usted no consta como hermano/a, ni constan vinculaciones con hermanos/as. Póngase en contacto con la hermandad.');
          }
        }

        if (respAsociate.directoryentryrelationship.length > 0 && respAsociate.directoryentryrelationship.some((br: any) => br.brother.entityType === 'brother')) {
          const modal = await this.modalCtrl.create({
            component: LoadAsociateBrotherComponent,
            cssClass: 'example-modal badge-modal',
            backdropDismiss: false,
            componentProps: {
              data: { relations: respAsociate.directoryentryrelationship, brother: respAsociate.brother }
            },
            mode: 'ios'
          });
          await modal.present();
          const { data } = await modal.onDidDismiss();
          if (data) {
            const respData = await this.loginService.changeTokenToAsociate(data);
            if (respData.ok) {
              this.open = false;
              await this.modalCtrl.dismiss(true);
            }
          } else {
            this.open = false;
            await this.modalCtrl.dismiss(true);
          }
        } else {
          this.open = false;
          await this.modalCtrl.dismiss(true);
        }
      }
      this.modalCtrl.dismiss();
    } else {
      return this.ui.alertError(resp.error);
    }
  }

  close() {
    this.gs.publishSomeData({ togglePublic: true, activeToggle: true });
    this.modalCtrl.dismiss(false);
  }

  async openForgotPasswordModal() {
    const modal = await this.modalCtrl.create({
      component: ForgotPasswordComponent,
      backdropDismiss: false,
      mode: 'ios'
    });
    await modal.present();
    await modal.onDidDismiss();
  }

  async openRegisterNewBrother() {
    const modal = await this.modalCtrl.create({
      component: NewBrotherModalComponent,
      cssClass: 'titularsModal',
      backdropDismiss: false,
      mode: 'ios',
      componentProps: {
      }
    });
    await modal.present();
    await modal.onDidDismiss();
  }

  async openRegister() {
    const modal = await this.modalCtrl.create({
      component: RegisterComponent,
      cssClass: 'titularsModal',
      backdropDismiss: false,
      mode: 'ios',
      componentProps: {
      }
    });
    await modal.present();
    await modal.onDidDismiss();
  }
}
