/* eslint-disable no-underscore-dangle */
import { Component, Inject, Input, OnInit, ViewChild, Renderer2, HostListener } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { EconomicService } from '../../services/portalServices/economic.service';
import { ModalController } from '@ionic/angular';
import { UiService } from '../../services/ui.service';
import { RedsysService } from '../../services/portalServices/redsys.service';
import { DOCUMENT } from '@angular/common';
import { AuthenticateModalComponent } from '../authenticate-modal/authenticate-modal.component';


declare let getInSiteForm;
declare let storeIdOper;

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {

  @Input() data: any = {};
  @ViewChild('card') card: IonInput = Object.create(null);
  @ViewChild('mmyy') mmyy: IonInput = Object.create(null);
  @ViewChild('cvv') cvv: IonInput = Object.create(null);
  @ViewChild('token') token: any;
  estiloBoton = '';
  estiloBody = '';
  estiloCaja = '{heigth: 400px}';
  estiloInputs = '';
  srcRedsysTest = 'https://sis-t.redsys.es:25443/sis/NC/sandbox/redsysV2.js';
  srcRedsysReal = 'https://sis.redsys.es/sis/NC/redsysV2.js';
  srcRedsysOper = '';
  merchanCode = '';
  terminal = '';
  order = '';
  real = false;

  cardText = 'Tarjeta de crédito o débito';
  numberText = 'Introduce tu número de tarjeta';
  spanText = '*Sólo se guardará en tu terminal';
  typeCard = '';
  cardNumber = '';
  mm = '';
  yy = '';
  dataCvv = '';
  path = '';

  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _documentHident: Document,
    @Inject(DOCUMENT) private _document: Document,
    private modalCtrl: ModalController,
    private ui: UiService,
    private redsysService: RedsysService
  ) { }

  pay(){
      // Inicia el proceso de pago
      if (this.real){
        this.redsysService.setPaymentRedsys(this.data.id, this.data.MD, this.data.order).then(async resp => {
          switch (resp.type) {
            case 1:
              // const elementHiden: any = this._documentHident.getElementById('hid');
              // const script2 = this._renderer2.createElement('script');
              // script2.type = 'application/javascript';
              // elementHiden.setAttribute('method', 'POST');
              // elementHiden.setAttribute('action', resp.url);
              // elementHiden.setAttribute('target', 'iframe_3DS_Method');
              // const threeDSMethodData = this._documentHident.createElement('input');
              // threeDSMethodData.setAttribute('type', 'hidden');
              // threeDSMethodData.setAttribute('name', 'threeDSMethodData');
              // threeDSMethodData.setAttribute('value', resp.buff);
              // elementHiden.appendChild(threeDSMethodData);
              // document.body.appendChild(elementHiden);
              // elementHiden.submit();
              // this._renderer2.appendChild(elementHiden, script2);

              this.redsysService.redsysPay(this.data.id, this.data.MD, this.data.order, resp.buff).then(async respPay => {
                if (respPay.ok && respPay.respTrata.response === 0) {
                  this.ui.alertInfo('Pago realizado correctamente','ok');
                  this.modalCtrl.dismiss();
                } else if (respPay.ok && respPay.respTrata.emv3ds) {
                  const dataResp = respPay.respTrata.emv3ds;
                  dataResp.idOrder = this.data.order;
                  const modal = await this.modalCtrl.create({
                    component: AuthenticateModalComponent,
                    componentProps: dataResp
                  });
                  await modal.present();
                  const { data } = await modal.onWillDismiss();
                  console.log(data);
                  this.modalCtrl.dismiss();
                }
              });
              break;

            case 2: // V: 2.1.0
              // const elementHiden1: any = this._documentHident.getElementById('hid');
              // const script3 = this._renderer2.createElement('script');
              // script3.type = 'application/javascript';
              // elementHiden1.setAttribute('method', 'POST');
              // elementHiden1.setAttribute('action', resp.url);
              // elementHiden1.setAttribute('target', 'iframe_3DS_Method');
              // const threeDSMethodData1 = this._documentHident.createElement('input');
              // threeDSMethodData1.setAttribute('type', 'hidden');
              // threeDSMethodData1.setAttribute('name', 'threeDSMethodData');
              // threeDSMethodData1.setAttribute('value', resp.buff);
              // elementHiden1.appendChild(threeDSMethodData1);
              // document.body.appendChild(elementHiden1);
              // elementHiden1.submit();
              // this._renderer2.appendChild(elementHiden1, script3);

              this.redsysService.redsysPay(this.data.id, this.data.MD, this.data.order, resp.buff).then(async respPay => {
                if (respPay.ok && respPay.respTrata.response === 0) {
                  window.alert('Pago realizado correctamente');
                  this.modalCtrl.dismiss();
                } else if (respPay.ok && respPay.respTrata.emv3ds) {
                  const dataResp = respPay.respTrata.emv3ds;
                  dataResp.idOrder = this.data.order;
                  const modal = await this.modalCtrl.create({
                    component: AuthenticateModalComponent,
                    componentProps: {data: dataResp}
                  });
                  await modal.present();
                  const { data } = await modal.onWillDismiss();
                  console.log(data);
                  this.modalCtrl.dismiss();
                }
              });
              break;

            case 3:
              break;
          }

        });
      } else {
        this.redsysService.setPaymentRedsysTest(this.data.id, this.data.MD, this.data.order).then(resp => {
          switch (resp.type) {
            case 1:
              // const elementHiden: any = this._documentHident.getElementById('hid');
              // const script2 = this._renderer2.createElement('script');
              // script2.type = 'application/javascript';
              // elementHiden.setAttribute('method', 'POST');
              // elementHiden.setAttribute('action', resp.url);
              // elementHiden.setAttribute('target', 'iframe_3DS_Method');
              // const threeDSMethodData = this._documentHident.createElement('input');
              // threeDSMethodData.setAttribute('type', 'hidden');
              // threeDSMethodData.setAttribute('name', 'threeDSMethodData');
              // threeDSMethodData.setAttribute('value', resp.buff);
              // elementHiden.appendChild(threeDSMethodData);
              // document.body.appendChild(elementHiden);
              // elementHiden.submit();
              // this._renderer2.appendChild(elementHiden, script2);

              this.redsysService.redsysPayTest(this.data.id, this.data.MD, this.data.order, resp.buff).then(async respPay => {
                if (respPay.ok && respPay.respTrata.response === 0) {
                  window.alert('Pago realizado correctamente');
                  this.modalCtrl.dismiss();
                } else if (respPay.ok && respPay.respTrata.emv3ds) {
                  const dataResp = respPay.respTrata.emv3ds;
                  dataResp.idOrder = this.data.order;
                  const modal = await this.modalCtrl.create({
                    component: AuthenticateModalComponent,
                    componentProps: {data: dataResp}
                  });
                  await modal.present();
                  const { data } = await modal.onWillDismiss();
                  console.log(data);
                  this.modalCtrl.dismiss();
                }
              });
              break;

            case 2: // V: 2.1.0
             const navigatorData = {
              navigator: window.navigator.userAgent.toLowerCase(),
              language: window.navigator.language,
              heigth: window.screen.height.toString(),
              width: window.screen.width.toString(),
              colorDepth: window.screen.colorDepth.toString(),
              timeZone: new Date().getTimezoneOffset()
             };
              // const elementHiden1: any = this._documentHident.getElementById('hid');
              // const script3 = this._renderer2.createElement('script');
              // script3.type = 'application/javascript';
              // elementHiden1.setAttribute('method', 'POST');
              // elementHiden1.setAttribute('action', resp.url);
              // elementHiden1.setAttribute('target', 'iframe_3DS_Method');
              // const threeDSMethodData1 = this._documentHident.createElement('input');
              // threeDSMethodData1.setAttribute('type', 'hidden');
              // threeDSMethodData1.setAttribute('name', 'threeDSMethodData');
              // threeDSMethodData1.setAttribute('value', resp.buff);
              // elementHiden1.appendChild(threeDSMethodData1);
              // document.body.appendChild(elementHiden1);
              // elementHiden1.submit();
              // this._renderer2.appendChild(elementHiden1, script3);

              this.redsysService.redsysPayTest(
                this.data.id, this.data.MD, this.data.order, resp.buff, navigatorData).then(async respPay => {
                if (respPay.ok && respPay.respTrata.response === 0) {
                  window.alert('Pago realizado correctamente');
                  this.modalCtrl.dismiss();
                } else if (respPay.ok && respPay.respTrata.emv3ds) {
                  const dataResp = respPay.respTrata.emv3ds;
                  dataResp.idOrder = this.data.order;
                  const modal = await this.modalCtrl.create({
                    component: AuthenticateModalComponent,
                    componentProps: {data: dataResp}
                  });
                  await modal.present();
                  const { data } = await modal.onWillDismiss();
                  console.log(data);
                  this.modalCtrl.dismiss();
                }
              });
              break;

            case 3:
              break;
          }

        });
      }
  }




  ngOnInit() {
    this.pay();
  }


  // valid(event: string) {
  //   this.card.clearInput = true;
  //   const permited = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-'];
  //   if (!permited.includes(event[event.length - 1])) {
  //     this.card.value = this.card.value = event.substring(0, event.length - 1);
  //     return;
  //   } else {
  //     const cardNumber = this.card.value.toString().replace(/-/g, '',);
  //     const validCard = valid.number(Number(cardNumber));
  //     if (String(this.card.value).length > 4) {
  //       if (!validCard.card) {
  //         this.ui.alertError('Tarjeta incorrecta');
  //         return;
  //       } else {
  //         this.typeCard = validCard.card.type;

  //         switch (this.typeCard) {
  //           case 'visa':
  //             this.path = '../../../assets/cards-img/visa.png';
  //             break;
  //           case 'mastercard':
  //             this.path = '../../../assets/cards-img/mastercard.png';
  //             break;
  //           case 'maestro':
  //             this.path = '../../../assets/cards-img/maestro.png';
  //             break;
  //           default:

  //             break;
  //         }

  //         if (!validCard.isPotentiallyValid && !validCard.isValid) {
  //           this.ui.alertError('Posible error en tarjeta, revisela');
  //         }
  //       }
  //     }

  //     if (cardNumber.length > 1 && cardNumber.length % 4 === 0 && cardNumber.length % 16 !== 0) {
  //       this.card.value = this.card.value.toString() + '-';
  //     }
  //     if (cardNumber.length === 16) {
  //       this.card.disabled = true;
  //       this.cardNumber = cardNumber;
  //       this.mmyy.setFocus();
  //     }
  //   }
  // }

  // enabledCard() {
  //   this.card.disabled = false;
  //   this.mmyy.disabled = false;
  // }

  // month(event: string) {
  //   const permited = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '/'];
  //   if (!permited.includes(event[event.length - 1])) {
  //     this.mmyy.value = this.mmyy.value = event.substring(0, event.length - 1);
  //   }
  //   const currentYear = new Date().getFullYear().toString();
  //   const currentMonth = new Date().getMonth() + 1;
  //   const currentYY = Number(currentYear.substring(2, 4));
  //   const mm = event.split('/')[0];
  //   const yy = event.split('/')[1];
  //   if (Number(mm) > 12) {
  //     this.ui.alertError('Mes no soportado');
  //     this.mmyy.value = '';
  //     return;
  //   }

  //   if (String(mm).length === 2 && !yy) {
  //     this.mmyy.value = this.mmyy.value.toString() + '/';
  //   }
  //   if (String(yy).length === 2 && (Number(yy) < currentYY)) {
  //     this.ui.alertError('Año no soportado');
  //     this.mmyy.value = '';
  //     return;
  //   } else if (String(yy).length === 2 && (Number(yy) === currentYY && Number(mm) < currentMonth)) {
  //     this.ui.alertError('Los datos de fecha de caducidad no son correctos');
  //     this.mmyy.value = '';
  //     return;
  //   }

  //   if (String(mm).length === 2 && String(yy).length === 2) {
  //     this.mm = mm;
  //     this.yy = yy;
  //     this.mmyy.disabled = true;
  //     this.cvv.setFocus();
  //   }
  // }

  // cvvVerifier(event: string) {
  //   const permited = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  //   if (!permited.includes(event[event.length - 1])) {
  //     //this.ui.alertError('No es posible un cvv con mas de 3 digitos.');
  //     this.cvv.value = event.substring(0, event.length - 1);
  //     return;
  //   } else if (event.length === 3) {
  //     this.dataCvv = event;
  //   }
  // }

  // async pay() {
  //   const card = {
  //     cvv: this.dataCvv,
  //     pan: this.cardNumber,
  //     mm: this.mm,
  //     yy: this.yy
  //   };

  //   // const respOrderId = await this.redsysService.generateOrder(this.debt.id);
  //   const redsysData = await this.redsysService.getRedsysData();
  //   if (!redsysData.ok) {
  //     return this.ui.alertError('Error en la pasarela de pago, contacte con la hermandad');
  //   }
  //   this.real = redsysData.mode.value === '1';

  //   if (this.real) {
  //     this.redsysService.initRedsysRest(this.debt.id, card).then(resp => {
  //       this.orderId = resp.order;
  //       switch (resp.type) {
  //         case 1:
  //           const elementHiden: any = this._documentHident.getElementById('hid');
  //           const script2 = this._renderer2.createElement('script');
  //           script2.type = 'application/javascript';
  //           elementHiden.setAttribute('method', 'POST');
  //           elementHiden.setAttribute('action', resp.url);
  //           elementHiden.setAttribute('target', 'iframe_3DS_Method');
  //           const threeDSMethodData = this._documentHident.createElement('input');
  //           threeDSMethodData.setAttribute('type', 'hidden');
  //           threeDSMethodData.setAttribute('name', 'threeDSMethodData');
  //           threeDSMethodData.setAttribute('value', resp.buff);
  //           elementHiden.appendChild(threeDSMethodData);
  //           document.body.appendChild(elementHiden);
  //           elementHiden.submit();
  //           this._renderer2.appendChild(elementHiden, script2);

  //           this.redsysService.redsysPay(this.debt.id, resp.buff).then(async respPay => {
  //             if (respPay.ok && respPay.respTrata.response === 0) {
  //               window.alert('Pago realizado correctamente');
  //               this.modalCtrl.dismiss();
  //             } else if (respPay.ok && respPay.respTrata.emv3ds) {
  //               const dataPay = respPay.respTrata.emv3ds;
  //               dataPay.idOrder = this.orderId;
  //               const dialogRef = await this.modalCtrl.create({
  //                 component: AuthenticateModalComponent,
  //                 componentProps: { data: dataPay },
  //               });
  //               await dialogRef.present();
  //               const { data } = await dialogRef.onWillDismiss();
  //               if (data) {
  //                 this.modalCtrl.dismiss();
  //               }
  //             }
  //           });
  //           break;

  //         case 2: // V: 2.1.0
  //           const elementHiden1: any = this._documentHident.getElementById('hid');
  //           const script3 = this._renderer2.createElement('script');
  //           script3.type = 'application/javascript';
  //           elementHiden1.setAttribute('method', 'POST');
  //           elementHiden1.setAttribute('action', resp.url);
  //           elementHiden1.setAttribute('target', 'iframe_3DS_Method');
  //           const threeDSMethodData1 = this._documentHident.createElement('input');
  //           threeDSMethodData1.setAttribute('type', 'hidden');
  //           threeDSMethodData1.setAttribute('name', 'threeDSMethodData');
  //           threeDSMethodData1.setAttribute('value', resp.buff);
  //           elementHiden1.appendChild(threeDSMethodData1);
  //           document.body.appendChild(elementHiden1);
  //           elementHiden1.submit();
  //           this._renderer2.appendChild(elementHiden1, script3);

  //           this.redsysService.redsysPay(this.debt.id, resp.buff).then(async respPay => {
  //             if (respPay.ok && respPay.respTrata.response === 0) {
  //               window.alert('Pago realizado correctamente');
  //               this.modalCtrl.dismiss();
  //             } else if (respPay.ok && respPay.respTrata.emv3ds) {
  //               const dataPay = respPay.respTrata.emv3ds;
  //               dataPay.idOrder = this.orderId;
  //               const dialogRef = await this.modalCtrl.create({
  //                 component: AuthenticateModalComponent,
  //                 componentProps: { data: dataPay },
  //               });
  //               await dialogRef.present();
  //               const { data } = await dialogRef.onWillDismiss();
  //               if (data) {
  //                 this.modalCtrl.dismiss();
  //               }
  //             }
  //           });
  //           break;

  //         case 3:
  //           break;
  //       }

  //     });
  //   } else {
  //     this.redsysService.initRedsysRestTest(this.debt.id, card).then(resp => {
  //       console.log(resp);
  //       this.orderId = resp.order;
  //       switch (resp.type) {
  //         case 1:
  //           const elementHiden: any = this._documentHident.getElementById('hid');
  //           const script2 = this._renderer2.createElement('script');
  //           script2.type = 'application/javascript';
  //           elementHiden.setAttribute('method', 'POST');
  //           elementHiden.setAttribute('action', resp.url);
  //           elementHiden.setAttribute('target', 'iframe_3DS_Method');
  //           const threeDSMethodData = this._documentHident.createElement('input');
  //           threeDSMethodData.setAttribute('type', 'hidden');
  //           threeDSMethodData.setAttribute('name', 'threeDSMethodData');
  //           threeDSMethodData.setAttribute('value', resp.buff);
  //           elementHiden.appendChild(threeDSMethodData);
  //           document.body.appendChild(elementHiden);
  //           elementHiden.submit();
  //           this._renderer2.appendChild(elementHiden, script2);

  //           this.redsysService.redsysPayTest(this.debt.id, resp.buff).then(async respPay => {
  //             if (respPay.ok && respPay.respTrata.response === 0) {
  //               window.alert('Pago realizado correctamente');
  //               this.modalCtrl.dismiss();
  //             } else if (respPay.ok && respPay.respTrata.emv3ds) {
  //               const dataPay = respPay.respTrata.emv3ds;
  //               dataPay.idOrder = this.orderId;
  //               console.log('ordr <--', dataPay.idOrder, this.orderId);
  //               const dialogRef = await this.modalCtrl.create({
  //                 component: AuthenticateModalComponent,
  //                 componentProps: { data: dataPay },
  //               });
  //               await dialogRef.present();
  //               const { data } = await dialogRef.onWillDismiss();
  //               if (data) {
  //                 this.modalCtrl.dismiss();
  //               }
  //             }
  //           });
  //           break;

  //         case 2: // V: 2.1.0
  //           const navigatorData = {
  //             navigator: window.navigator.userAgent.toLowerCase(),
  //             language: window.navigator.language,
  //             heigth: window.screen.height.toString(),
  //             width: window.screen.width.toString(),
  //             colorDepth: window.screen.colorDepth.toString(),
  //             timeZone: new Date().getTimezoneOffset()
  //           };
  //           const elementHiden1: any = this._documentHident.getElementById('hid');
  //           const script3 = this._renderer2.createElement('script');
  //           script3.type = 'application/javascript';
  //           elementHiden1.setAttribute('method', 'POST');
  //           elementHiden1.setAttribute('action', resp.url);
  //           elementHiden1.setAttribute('target', 'iframe_3DS_Method');
  //           const threeDSMethodData1 = this._documentHident.createElement('input');
  //           threeDSMethodData1.setAttribute('type', 'hidden');
  //           threeDSMethodData1.setAttribute('name', 'threeDSMethodData');
  //           threeDSMethodData1.setAttribute('value', resp.buff);
  //           elementHiden1.appendChild(threeDSMethodData1);
  //           document.body.appendChild(elementHiden1);
  //           elementHiden1.submit();
  //           this._renderer2.appendChild(elementHiden1, script3);

  //           this.redsysService.redsysPayTest(this.debt.id, resp.buff, navigatorData).then(async respPay => {
  //             if (respPay.ok && respPay.respTrata.response === 0) {
  //               window.alert('Pago realizado correctamente');
  //               this.modalCtrl.dismiss();
  //             } else if (respPay.ok && respPay.respTrata.emv3ds) {
  //               const dataPay = respPay.respTrata.emv3ds;
  //               dataPay.idOrder = this.orderId;
  //               const dialogRef = await this.modalCtrl.create({
  //                 component: AuthenticateModalComponent,
  //                 componentProps: { data: dataPay },
  //               });
  //               await dialogRef.present();
  //               const { data } = await dialogRef.onWillDismiss();
  //               if (data) {
  //                 this.modalCtrl.dismiss();
  //               }
  //             }
  //           });
  //           break;

  //         case 3:
  //           break;
  //       }

  //     });
  //   }

  // }

  close() {
    this.modalCtrl.dismiss();
  }

  // verifiedGlobalCard(): boolean {
  //   return this.dataCvv.length === 3 && this.cardNumber.length === 16 && (this.mm.length + this.yy.length) === 4;
  // }

}
