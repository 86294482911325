import { Component, Input, OnInit } from '@angular/core';
import { GlobalServiceService } from '../../services/global-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-read-content-copmponent',
  templateUrl: './read-content-copmponent.component.html',
  styleUrls: ['./read-content-copmponent.component.scss'],
})
export class ReadContentCopmponentComponent implements OnInit {

  @Input()h: any = {};
  constructor(private gs: GlobalServiceService, private router: Router) { }

  ngOnInit() {
  }

  enableButton() {
    this.gs.publishSomeData({ button: true });
    this.router.navigate(['brotherhood']);
    // this.router.navigate([['brotherhood']]);
  }
}
