import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor(private alertCrtl: AlertController,
              private toastController: ToastController) { }

  async alertError(mensaje: string) {
    const alert = await this.alertCrtl.create({
      header: 'Error',
      message: mensaje,
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    alert.present();
  }

  async alertInfo(header: string, message: string) {
    const alert = await this.alertCrtl.create({
      header,
      message,
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    alert.present();
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

  areYouSure(message: string){
    return new Promise<boolean>(async resolve => {
      const alert = await this.alertCrtl.create({
        header: 'Confirmar Baja',
        message,
        buttons: [
          {
            text: 'NO',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              resolve(false);
            }
          }, {
            text: 'SÍ',
            handler: () => {
              resolve(true);
            }
          }
        ]
      });
      alert.present();

    });
  }
  presentConfirm(message: string){
    return new Promise<boolean>(async resolve => {
      const alert = await this.alertCrtl.create({
        header: 'Cerrar Sesión',
        message,
        buttons: [
          {
            text: 'NO',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              resolve(false);
            }
          }, {
            text: 'SÍ',
            handler: () => {
              resolve(true);
            }
          }
        ]
      });
      alert.present();

    });
  }


}
