import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToggleComponent } from './toggle/toggle.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { ImgGaleryAlbumComponent } from './img-galery-album/img-galery-album.component';
import { TabsPralComponent } from './tabs-pral/tabs-pral.component';
import { IonicModule } from '@ionic/angular';
import { TabsPortalComponent } from './tabs-portal/tabs-portal.component';
import { QuillModule } from 'ngx-quill';
import { ReadContentCopmponentComponent } from './read-content-copmponent/read-content-copmponent.component';
import { FooterComponent } from './footer/footer.component';
import { FooterModalComponent } from './footer-modal/footer-modal.component';
import { NoDataComponent } from './no-data/no-data.component';
import { NoGroupsAndRelationsComponent } from './no-groups-and-relations/no-groups-and-relations.component';
import { NoDataProcessionComponent } from './no-data-procession/no-data-procession.component';
import { CountdownComponent } from './countdown/countdown.component';

const COMPONENTS = [
  ToggleComponent,
  BackButtonComponent,
  ImgGaleryAlbumComponent,
  TabsPralComponent,
  TabsPortalComponent,
  ReadContentCopmponentComponent,
  FooterComponent,
  FooterModalComponent,
  NoDataComponent,
  NoGroupsAndRelationsComponent,
  NoDataProcessionComponent,
  CountdownComponent
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
      CommonModule,
      IonicModule,
      QuillModule.forRoot()
    ],
    exports: COMPONENTS
  })

  export class ComponentsModule { };
