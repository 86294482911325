import { Injectable } from '@angular/core';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root'
})
export class RedsysService {

  constructor(private ds: DataService) { }

  getRedsysData() {
    return new Promise<any>(resolve => {
      this.ds.getData('administration/config/redsysParams').then(resp => resolve(resp));
    });
  }


  initRedsysRestTest(id: number, payload: any) {
    return new Promise<any>(resolve => {
      this.ds.setData('redsysPay/initRestTest/' + id, { payload }).then(resp => resolve(resp));
    });
  }
  initRedsysRest(id: number, payload: any) {
    return new Promise<any>(resolve => {
      this.ds.setData('redsysPay/initRest/' + id, { payload }).then(resp => resolve(resp));
    });
  }

  async generateOrder(idDebt: number){
    return new Promise<any>(resolve => {
      // eslint-disable-next-line max-len
      this.ds.getData('redsysPay/order/' + idDebt, 'GhercofInogaAppPayClod2021200$%hermandadesYcofrades2007¢%&/11198210404$$·').then(resp => {
        resolve(resp);
      });
    });
  }

  async generateDebtBrotherApplication(birthDate: Date){
    return new Promise<any>(resolve => {
      // eslint-disable-next-line max-len
      this.ds.setData('debt/newBrother', {birthDate}, 'GhercofInogaAppPayClod2021200$%hermandadesYcofrades2007¢%&/11198210404$$·').then(resp => {
        resolve(resp);
      });
    });
  }

  async deleteDebtBrotherApplication(idDebt: number){
    return new Promise<any>(resolve => {
      // eslint-disable-next-line max-len
      this.ds.setData('debt/deleteNewBrother/' + idDebt, {}, 'GhercofInogaAppPayClod2021200$%hermandadesYcofrades2007¢%&/11198210404$$·').then(resp => {
        resolve(resp);
      });
    });
  }
  async setPaymentRedsys(idDebt: number, idOper: any, order: string){

    return new Promise<any>(resolve => {
      // eslint-disable-next-line max-len
      this.ds.setData('redsysPay/init/' + idDebt, {idOper, order}, 'GhercofInogaAppPayClod2021200$%hermandadesYcofrades2007¢%&/11198210404$$·').then(resp => {
        resolve(resp);
      });
    });
  }

  async redsysPay( idDebt: number, idOper: any, order: string, _3ds: string){
    return new Promise<any>(resolve => {
      // eslint-disable-next-line max-len
      this.ds.setData('redsysPay/pay/' + idDebt, {idOper, order, _3ds}, 'GhercofInogaAppPayClod2021200$%hermandadesYcofrades2007¢%&/11198210404$$·').then(resp => {
          resolve(resp);
        });
    });

  }
  async generateOrderTest(idDebt: number){
    return new Promise<any>(resolve => {
      // eslint-disable-next-line max-len
      this.ds.getData('redsysPay/orderTest/' + idDebt, 'GhercofInogaAppPayClod2021200$%hermandadesYcofrades2007¢%&/11198210404$$·').then(resp => {
        resolve(resp);
      });
    });
  }

  async setPaymentRedsysTest(idDebt: number, idOper: any, order: string){
    return new Promise<any>(resolve => {
      // eslint-disable-next-line max-len
      this.ds.setData('redsysPay/initTest/' + idDebt, {idOper, order}, 'GhercofInogaAppPayClod2021200$%hermandadesYcofrades2007¢%&/11198210404$$·').then(resp => {
        resolve(resp);
      });
    });
  }

  async redsysPayTest( idDebt: number, idOper: any, order: string, _3ds: string, navigatorData?: any){
    return new Promise<any>(resolve => {
      // eslint-disable-next-line max-len
      this.ds.setData('redsysPay/payTest/' + idDebt, {idOper, order, _3ds, navigatorData}, 'GhercofInogaAppPayClod2021200$%hermandadesYcofrades2007¢%&/11198210404$$·').then(resp => {
          resolve(resp);
        });
    });

  }

  async getOrderPay(idDebt: any){
    return new Promise<any>(resolve => {
      // eslint-disable-next-line max-len
      this.ds.getData('redsysPay/currentOrder/' + idDebt, 'GhercofInogaAppPayClod2021200$%hermandadesYcofrades2007¢%&/11198210404$$·').then(resp => resolve(resp));
    });
  }

  async createOrderPayNoDebt(data: any){
    return new Promise<any>(resolve => {
      // eslint-disable-next-line max-len
      this.ds.setData('redsysPay/createOrderPayNoDebt/', data, 'GhercofInogaAppPayClod2021200$%hermandadesYcofrades2007¢%&/11198210404$$·').then(resp => {
        resolve(resp);
      });
    });
  }
  async getOrderPayPts(idOrd: any){
    return new Promise<any>(resolve => {
      // eslint-disable-next-line max-len
      this.ds.getData('redsysPay/currentOrderPts/' + idOrd, 'GhercofInogaAppPayClod2021200$%hermandadesYcofrades2007¢%&/11198210404$$·').then(resp => resolve(resp));
    });
  }

}
