import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiConfig } from './apiConfig';
import { TitularModalComponent } from './modals/titular-modal/titular-modal.component';
import { ComponentsModule } from './components/components.module';
import { LoginComponent } from './modals/login/login.component';

import { GaleryModalComponent } from './modals/galery-modal/galery-modal.component';
import { ReadNewsComponent } from './modals/read-news/read-news.component';

import { PaymentModalComponent } from './modals/payment-modal/payment-modal.component';
import { UpdateDataModalComponent } from './modals/update-data-modal/update-data-modal.component';
import { PipesModule } from './pipes/pipes.module';
import { UpdatePaymentModalComponent } from './modals/update-payment-modal/update-payment-modal.component';
import { AddDocumentModalComponent } from './modals/add-document-modal/add-document-modal.component';
import { AuthenticateModalComponent } from './modals/authenticate-modal/authenticate-modal.component';
import { ReadNotificationComponent } from './modals/read-notification/read-notification.component';
import { ReadMeetingsModalComponent } from './modals/read-meetings-modal/read-meetings-modal.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ContactDataModalComponent } from './modals/contact-data-modal/contact-data-modal.component';
import { BrotherDataModalComponent } from './modals/brother-data-modal/brother-data-modal.component';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { ForgotPasswordComponent } from './modals/forgot-password/forgot-password.component';
import { NewBrotherModalComponent } from './modals/new-brother-modal/new-brother-modal.component';
import { RegisterComponent } from './modals/register/register.component';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ConfirmationNewBrotherModalComponent } from './modals/confirmation-new-brother-modal/confirmation-new-brother-modal.component';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { UnsubscribeModalComponent } from './modals/unsubscribe-modal/unsubscribe-modal.component';
import { LegalModalComponent } from './modals/legal-modal/legal-modal.component';
import { ReadCorrespondenceComponent } from './modals/read-correspondence/read-correspondence.component';
import { ChangePasswordModalComponent } from './modals/change-password-modal/change-password-modal.component';
import { OptionsModalComponent } from './modals/options-modal/options-modal.component';
import { WeddingModalComponent } from './modals/wedding-modal/wedding-modal.component';
import { PtsModalComponent } from './modals/pts-modal/pts-modal.component';
import { AddNewBadgeModalComponent } from './modals/add-new-badge-modal/add-new-badge-modal.component';
import { SelectPtsmodeComponent } from './modals/select-ptsmode/select-ptsmode.component';
import { LoadAsociateBrotherComponent } from './modals/login/load-asociate-brother/load-asociate-brother.component';
import { BrotherButtonAccessComponent } from './modals/login/load-asociate-brother/brother-button-access/brother-button-access.component';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';


import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { QuillModule } from 'ngx-quill';



const loadSimpliData = (initService: ApiConfig) => async () => {
    await Promise.all([
      initService.loadBasicData(),
      initService.loadAppData(),
      initService.loadRedsysData()
    ]);
};

const COMPONENTS = [
  AppComponent,
  TitularModalComponent,
  LoginComponent,
  GaleryModalComponent,
  ReadNewsComponent,
  PaymentModalComponent,
  UpdateDataModalComponent,
  UpdatePaymentModalComponent,
  AddDocumentModalComponent,
  AuthenticateModalComponent,
  ReadNotificationComponent,
  ReadMeetingsModalComponent,
  ContactDataModalComponent,
  BrotherDataModalComponent,
  ForgotPasswordComponent,
  NewBrotherModalComponent,
  RegisterComponent,
  ConfirmationNewBrotherModalComponent,
  UnsubscribeModalComponent,
  LegalModalComponent,
  ReadCorrespondenceComponent,
  ChangePasswordModalComponent,
  OptionsModalComponent,
  WeddingModalComponent,
  PtsModalComponent,
  AddNewBadgeModalComponent,
  SelectPtsmodeComponent,
  LoadAsociateBrotherComponent,
  BrotherButtonAccessComponent,
  AlertModalComponent
];

@NgModule({
  declarations: [
    COMPONENTS
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    FormsModule,
    QuillModule.forRoot(),
    IonicStorageModule.forRoot(),
    PipesModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    InAppBrowser,
    //SocialSharing,
    EmailComposer,
    StatusBar,
    CallNumber,
    {
      provide: APP_INITIALIZER,
      useFactory: loadSimpliData,
      deps: [ApiConfig],
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Camera
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule { }
