import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EventsService } from 'src/app/services/portalServices/events.service';
import { environment } from 'src/environments/environment.prod';
import { GlobalServiceService } from '../../services/global-service.service';

@Component({
  selector: 'app-wedding-modal',
  templateUrl: './wedding-modal.component.html',
  styleUrls: ['./wedding-modal.component.scss'],
})
export class WeddingModalComponent implements OnInit{

  @Input() data: any = {};
  finishDate: Date = new Date();
  url: string = environment.backendUrl || '';
  urlImg: string = '';
  eventInit: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private es: EventsService,
    private gs: GlobalServiceService
    
  ) { 
  }
  ngOnInit(): void {
    const now = new Date();
    if (new Date(this.data.initDate).getTime() >= now.getTime()) {
      this.finishDate = new Date(this.data.initDate);
    } else {
      this.finishDate = new Date(this.data.endDate);
      this.eventInit = true;
    }
    this.urlImg = this.url+'/administration/events/image/'+this.data.id
}

  back() {
    this.modalCtrl.dismiss(true);
  }
}
