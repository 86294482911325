/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProcesionService } from '../../services/portalServices/procesion.service';
import { UiService } from '../../services/ui.service';
import { brotherhoodData } from '../../../environments/globales';

@Component({
  selector: 'app-add-new-badge-modal',
  templateUrl: './add-new-badge-modal.component.html',
  styleUrls: ['./add-new-badge-modal.component.scss'],
})
export class AddNewBadgeModalComponent implements OnInit {

  @Input() data;
  @Input() idDirectoryEntry;
  @Input() ptsMode;
  secondForm: FormGroup;
  pasosList: any[] = [];
  heritageList: any[] = [];
  procesionSelected = false;
  selectedOptions: any;
  selectedPasoId: number;
  userSelectedHeritage: any;
  selectedHeritageName: string;
  selectedHeritageId: any;
  procesionMode = brotherhoodData.procesionMode;
  availableButton = false;
  selectedValue: any = {};
  selectedIds = [];
  heritagePA: any[] = [
    { id: 'VPA', name: 'VARA QUE ME CORRESPONDA POR ANTIGÜEDAD', vpa: true },
    { id: 'IPA', name: 'INSIGNIA QUE ME CORRESPONDA POR ANTIGÜEDAD', ipa: true }
  ];
  allowIPAVPA = false;


  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private ps: ProcesionService,
    private ui: UiService
  ) {
    // Segundo Formulario - Solicitud Insignias
    this.secondForm = this.fb.group({
      // priority: [1, Validators.required],
      idPasoInProcesion: ['', Validators.required],
      idHeritageInProcesion: ['', Validators.required]
    });
  }

  async ngOnInit() {
    await this.loadConfigHR();
    await this.loadPasosInProcesion();
  }

  async loadConfigHR() {
    const resp = await this.ps.getConfigHr(this.data.procesion.id);
    if (!resp.ok) {
      this.ui.alertError(resp.error);
      this.modalCtrl.dismiss('close');
    }
    if (resp.hrc && resp.hrc.alowIpaVpa){
      this.allowIPAVPA = true;
    }
  }

  back() {
    this.modalCtrl.dismiss();
  }

  async loadPasosInProcesion() {
    const pasosInResp = await this.ps.loadPasosInProcesion(this.data.procesion.id);
    if (!pasosInResp.ok) {
      return this.ui.alertError(pasosInResp.error);
    }
    this.pasosList = pasosInResp.pasosInProcesion;
    this.heritageList = [];
    this.secondForm.controls['idHeritageInProcesion'].setValue('');
  }

  async loadHeritages() {
    if (this.ptsMode === 1) {await this.loadHeritagesHSA();}
    else {await this.loadHeritagesInProcesion();}
  }

  async loadHeritagesInProcesion() {
    const pipResp = await this.ps.loadHeritagesInProcesion(this.secondForm.get('idPasoInProcesion').value);
    if (!pipResp.ok) {
      return this.ui.alertError(pipResp.error);
    }
    this.heritageList = pipResp.hipPortal || [];
    if (this.allowIPAVPA) {this.heritageList.unshift(...this.heritagePA);}
    this.procesionSelected = true;
  }

  async loadHeritagesHSA() {
    const respHSA = await this.ps.getHeritagesSimpleAssignment(this.secondForm.get('idPasoInProcesion').value);
    if (!respHSA.ok) {return this.ui.alertError(respHSA.error);}

    this.heritageList = respHSA.hsa || [];
    this.procesionSelected = true;
  }

  async onHeritageSelected(event: any) {
    this.availableButton = true;
    this.selectedValue.idHeritageInProcesion = event.id;
    this.selectedValue.name = event.name;
    this.selectedValue.simpleAssignment = event.simpleAssignment || false;
    this.selectedValue.idProcesion = event.idProcesion || this.data.procesion?.id;
    this.selectedValue.idPasoInProcesion = event.idPasoInProcesion || this.secondForm.get('idPasoInProcesion').value;
    this.selectedValue.idDirectoryEntry = this.idDirectoryEntry;
    this.selectedValue.fee = Number(event.fee) + Number(event.pasoInProcesion?.feePts) + Number(event.procesionRole?.fee);
    this.selectedValue.feeYounger = Number(event.feeYounger) + Number(event.pasoInProcesion?.feePtsYounger) + Number(event.procesionRole?.feeYounger);
    this.selectedValue.ipa = event.ipa;
    this.selectedValue.vpa = event.vpa;
  }

  async addBadge() {
    await this.modalCtrl.dismiss(this.selectedValue);
  }

}
