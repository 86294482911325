import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoginService } from '../../services/login.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {


  email = '';
  open = true;

  constructor(
    private modalCtrl: ModalController,
    private loginService: LoginService,
    private ui: UiService
  ) { }

  ngOnInit() { }

  back() {
    this.modalCtrl.dismiss();
  }

  async onSubmit() {
    await this.loginService.restorePass(this.email);
    this.ui.alertInfo('Revise su correo electrónico', '');
    this.back();
  }

}
