import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'maritalStatus'
  })
  export class MaritalStatusPipe implements PipeTransform {

    transform(id: any): any {
      switch(id) {
        case 1:
          return 'Casado/a';
        case 2:
          return 'Soltero/a';
        case 3:
          return 'Separado/a';
        case 4:
          return 'Viudo/a';
        default:
          return id;
      }
    }
  }
