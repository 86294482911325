/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';

const URL = environment.backendUrl;

@Injectable({
  providedIn: 'root'
})

// Servicio CRUD gestión de datos genéricos
export class DataService {

  header: any = {
  };
  private _storage: Storage | null = null;

  constructor(private http: HttpClient, private routes: Router, private storage: Storage) {
    this.init();
  }


  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    // eslint-disable-next-line no-underscore-dangle
    this._storage = storage;
  }

  // Carga de token y añadido a header
  private async cargarHeader() {
    const token = await this.storage.get('tokenApp');
    if (token) {
      this.header.token = token;
      return;
    } else {
      this.header.token = '';
      return;
    }
  }


  // Solicita datos al Backend
  // eslint-disable-next-line @typescript-eslint/member-ordering
  async getData(consulta: string, tk?: string) {
    if (tk){
      this.header.token = tk;
    } else {
      await this.cargarHeader();
    }
    return new Promise<any>(resolve => {
      this.http.get(`${URL}/${consulta}`, { headers: this.header }).subscribe((resp: any) => {
        // if (resp.autenticationError){
        //   this.routes.navigate(['/authentication/login']);
        //   return;
        // }
        resolve(resp);
      });
    });
  }

  // Envia datos al Backend (Crea un nuevo registro)
  async setData(consulta: string, datos: any, tk?: string) {
    if (tk){
      this.header.token = tk;
    } else {
      await this.cargarHeader();
    }
    return new Promise<any>(resolve => {
      this.http.post(`${URL}/${consulta}`, datos, { headers: this.header }).subscribe((resp: any) => {
        if (resp.autenticationError) {
          this.routes.navigate(['/authentication/login']);
          return;
        }
        resolve(resp);
      });
    });
  }

  // Actualiza datos en el Backend
  async updateData(consulta: string, datos: any) {
    await this.cargarHeader();
    return new Promise<any>(resolve => {
      this.http.put(`${URL}/${consulta}`, datos, { headers: this.header }).subscribe((resp: any) => {
        if (resp.autenticationError) {
          this.routes.navigate(['/authentication/login']);
          return;
        }
        resolve(resp);
      });
    });
  }
  // Filtro de datos en el Backend
  async filterData(consulta: string, datos: any) {
    await this.cargarHeader();
    return new Promise<any>(resolve => {
      this.http.put(`${URL}/${consulta}`, datos, { headers: this.header }).subscribe((resp: any) => {
        if (resp.autenticationError) {
          this.routes.navigate(['/authentication/login']);
          return;
        }
        resolve(resp);
      });
    });
  }

  // Elimina un registro en el Backend
  async delData(consulta: string) {
    await this.cargarHeader();
    return new Promise<any>(resolve => {
      this.http.delete(`${URL}/${consulta}`, { headers: this.header }).subscribe((resp: any) => {
        if (resp.autenticationError) {
          this.routes.navigate(['/authentication/login']);
          return;
        }
        resolve(resp);
      });
    });
  }

  // Upload documento a un directorio /Uploads
  async uploadDocumento(file: any) {
    await this.cargarHeader();
    const formData: FormData = new FormData();
    formData.append('doc', file.doc);
    formData.append('documentType', file.documentType);
    return new Promise((resolve) => {
      this.http.post(`${URL}/ph/document/upload`, formData, { headers: this.header }).subscribe((resp: any) => {
        if (resp.autenticationError) {
          this.routes.navigate(['/authentication/login']);
          return;
        }
        resolve(resp);
      });
    });
  }

  async uploadImageProfile(img: any){
    await this.cargarHeader();
    return new Promise<any>(resolve => {
      this.http.post(`${URL}/ph/document/upload/uploadImageProfile`, {img}, {headers: this.header}).subscribe( resp => {
        resolve(resp);
      });
    });
  }

  // Download documento a un directorio /Uploads
  async downloadDocumento(consulta: string) {
    await this.cargarHeader();
    return new Promise((resolve) => {
      this.http.get(`${URL}/ph/document/download/${consulta}`, { headers: this.header, responseType: 'blob' }).subscribe((resp: any) => {
        if (resp.autenticationError) {
          this.routes.navigate(['/authentication/login']);
          return;
        }
        resolve(resp);
      });
    });
  }

  // Obtiene la Imagen del Hermano, en caso de que exista.
  async downloadImagenPerfil() {
    await this.cargarHeader();
    return new Promise((resolve) => {
      this.http.get(`${URL}/docService/imagen`, { headers: this.header, responseType: 'blob' }).subscribe((resp: any) => {
        if (resp.autenticationError) {
          this.routes.navigate(['/authentication/login']);
          return;
        }
        resolve(resp);
      });
    });
  }

  // Elimina documento
  async delDocumento(consulta: string) {
    await this.cargarHeader();
    return new Promise(resolve => {
      this.http.delete(`${URL}/docService/delete/${consulta}`, { headers: this.header }).subscribe((resp: any) => {
        if (resp.autenticationError) {
          this.routes.navigate(['/authentication/login']);
          return;
        }
        resolve(resp);
      });
    });
  }

  // Almacena en storage
  async saveStorege(tabla: string, dato: any) {
    await this.storage.set(tabla, dato);
    //const save = localStorage.setItem(tabla, dato);
    return {
      ok: true,
    };

  }

  // Lee de storage
  async readStorage(tabla: string) {
    const read = await this.storage.get(tabla);
    //const read = localStorage.getItem(tabla);
    if (!read) {
      return {
        ok: false,
        error: 'No se ha leido desde el amacenamiento interno.'
      };
    }
    return {
      ok: true,
      read
    };
  }

  // Elimina del storage una tabla
  async delStorage(tabla: string) {
    //localStorage.removeItem(tabla);
    await this.storage.clear();
    return {
      ok: true
    };
  }

}
