import { Component, OnInit } from '@angular/core';
import { Platform, ModalController, NavController } from '@ionic/angular';
import { GlobalServiceService } from './services/global-service.service';
import { PushService } from './services/push.service';
import { platformRun } from 'src/environments/globales';
import { LoginService } from './services/login.service';
import { brotherhoodData, globalUser } from '../environments/globales';
import { UiService } from './services/ui.service';
import { Storage } from '@ionic/storage-angular';
import { LoginComponent } from './modals/login/login.component';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  name: any = brotherhoodData.SimpliBrotherhoodName;
  backActive = false;
  back = '/';
  customColor = '--white';
  exitEnabled = false;
  menuButton = false;
  //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //Add 'implements OnInit' to the class.
  public;
  private;
  active = false;
  ios = false;
  toggleLeftText = 'La Hermandad';
  toggleRightText = 'Mi Portal';
  hidenMenu = false;
  user = globalUser;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private gs: GlobalServiceService,
    private router: Router,
    private pushService: PushService,
    private ptf: Platform,
    private ui: UiService,
    private navController: NavController,
    private storage: Storage,
    private modalCtrl: ModalController,
    private statusBar: StatusBar,
    private loginService: LoginService) {

    if (this.ptf.is('cordova')) {
      this.pushService.iniciarPush();
    }
    if (!this.ptf.is('android')) {
      this.pushService.iniciarPush();
      this.ios = true;
      if (this.ptf.height() <= 812) {
        this.ios = false;
      }
      platformRun.ios = true;
      platformRun.heightPlatform = this.ptf.height();
      platformRun.android = false;
    } else if (this.ptf.is('ios')) {
      this.pushService.iniciarPush();
    }
  }
  async ngOnInit() {
    await this.storage.create();
    await this.loginService.checkToken();
    this.gs.getObservable().subscribe(async (data) => {
      if (data.togglePublic !== undefined) {
        if (data.togglePublic) {
          this.router.navigate(['']);
        } else {
          const login = await this.loginService.checkToken();
          if (login.ok) {
            this.router.navigate(['/portal-tabs']);
          }
        }
        if (data.togglePublic) {
          this.public = true;
          this.private = false;
        } else {
          this.public = false;
          this.private = true;
        }
      }
      if (data.name) {
        this.name = data.name;
      }
      if (data.backActive) {
        this.backActive = true;
      } else {
        this.backActive = false;
      }
      if (data.back) {
        this.back = data.back;
      }
      if (data.exitEnabled) {
        this.exitEnabled = true;
      } else {
        this.exitEnabled = false;
      }
      if (data.activeToggle) {
        this.active = false;
        this.activeToggle();
      }
    });

    this.activeToggle();

    // Statusbar Mobile
    this.statusBar.overlaysWebView(false);

    /**
     * Elegir el color de Background del StatusBar según el color de la Hermandad.
     */

    // Blue Color Theme
    // this.statusBar.backgroundColorByHexString('#093171');

    // Green Color Theme
    // this.statusBar.backgroundColorByHexString('#097144');

    // Bordeaux Color Theme
    // this.statusBar.backgroundColorByHexString('#710921');

    // Purple Color Theme
    // this.statusBar.backgroundColorByHexString('#380971');

    // Black Color Theme
    this.statusBar.backgroundColorByHexString('#07080E');
  }

  onScroll(event: { detail: { scrollTop: number } }) {
    const footerImg = document.querySelector('#footerImg');
    if (event.detail.scrollTop > 0 && !this.hidenMenu) {
      this.hidenMenu = true;
      this.menuButton = this.hidenMenu;
    } else if (event.detail.scrollTop < 10 && this.hidenMenu) {
      this.hidenMenu = false;
      this.menuButton = this.hidenMenu;
    }
    if (this.menuButton) {
      footerImg.classList.add('img-top');
      footerImg.classList.add('up-top');
    } else {
      footerImg.classList.remove('img-top');
      footerImg.classList.remove('up-top');
    }
  }

  displayMenu() {
    this.hidenMenu = false;
    this.menuButton = false;

  }

  getPublic() {
    // this.gs.publishSomeData({menuButton: !(this.public && !this.hidenMenu)});
    return this.public && !this.hidenMenu;
  }
  getPrivate() {
    // this.gs.publishSomeData({menuButton: !(this.private && !this.hidenMenu)});
    return this.private && !this.hidenMenu;
  }

  backControll() {
    this.router.navigate([this.back]);
  }

  activeToggle() {
    if (this.active) {
      this.toggleHermandad();
    } else {
      this.togglePortal();
    }
  }

  async logout() {
    const confirm = await this.ui.presentConfirm('¿Desea salir de la sesión del Portal del Hermano/a?');
    if (confirm) {
      this.loginService.logout().then(resp => {
        if (resp) {
          this.exitEnabled = false;
          this.router.navigate(['']);
          this.active = false;
          this.activeToggle();
          this.user.id = null;
        }
      });
      this.gs.publishSomeData({ togglePublic: true, activeToggle: true });
      this.router.navigate(['/']);
    }
  }

  async togglePortal() {
    this.active = !this.active;
    const toggle = document.querySelector('.switch');
    const brotherHoodActive: any = document.querySelector('#brotherHood');
    const brothersPortalActive: any = document.querySelector('#brothersPortal');
    const toggleBall: any = document.querySelector('.switch-ball');

    brotherHoodActive.style.backgroundColor = 'var(--background-toggle)';
    brotherHoodActive.style.boxShadow = 'none';
    brothersPortalActive.style.backgroundColor = 'var(--white)';
    brothersPortalActive.style.boxShadow = 'inset 0px 0px 8px  rgba(0, 0, 0, 0.25)';
    toggleBall.style.background = 'var(--firstcolor) var(--toggle-icon-brotherhood)';
    toggleBall.style.transform = 'translateX(0px)';
    this.gs.publishSomeData({ togglePublic: true });
    await this.router.navigate(['/']);

  }
  async toggleHermandad() {
    this.active = !this.active;
    const toggle = document.querySelector('.switch');
    const brotherHoodActive: any = document.querySelector('#brotherHood');
    const brothersPortalActive: any = document.querySelector('#brothersPortal');
    const toggleBall: any = document.querySelector('.switch-ball');

    brotherHoodActive.style.backgroundColor = 'var(--white)';
    brotherHoodActive.style.boxShadow = 'inset 0px 0px 8px  rgba(0, 0, 0, 0.25)';
    brothersPortalActive.style.backgroundColor = 'var(--background-toggle)';
    brothersPortalActive.style.boxShadow = 'none';
    toggleBall.style.transform = 'translateX(42px)';
    toggleBall.style.background = 'var(--firstcolor) var(--toggle-icon-brothersportal)';
    this.gs.publishSomeData({ togglePublic: false });
    let modal: any;


    const resp = await this.loginService.checkToken();
    if (!resp.ok) {
      modal = await this.modalCtrl.create({
        component: LoginComponent,
        backdropDismiss: false,
        cssClass: 'titularsModal',
        mode: 'ios'
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.gs.publishSomeData({ name: 'PORTAL DEL HERMANO', exitEnabled: true, init: true });
        await this.navController.navigateRoot('/portal-tabs');
      } else {
        this.gs.publishSomeData({ init: false });
        await this.navController.navigateRoot('/');

      }
    } else {
      this.gs.publishSomeData({ name: 'PORTAL DEL HERMANO', exitEnabled: true, init: true });
    }
  }

}
