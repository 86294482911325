export const environment = {
  production: true,
// backendUrl: 'https://demo2.ghercof.com/api'
  //  backendUrl: 'https://expiracionmalaga.ghercof.com/api'
  // backendUrl: 'https://macarena.ghercof.com/api'
  // backendUrl: 'http://localhost:1337/api'
  // backendUrl: 'http://192.168.0.29:1337/api'
  // backendUrl: 'https://lapazsevilla.ghercof.com/api'
  // backendUrl: 'https://sanbernardo.ghercof.com/api'
  // backendUrl: 'https://pasionsevilla.ghercof.com/api'
  // backendUrl: 'https://esperanzahuelva.ghercof.com/api'
  // backendUrl: 'https://gitanossevilla.ghercof.com//api'
  backendUrl: 'https://perdonorihuela.ghercof.com/api'

};
