import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../../services/portalServices/document.service';
import { Document } from '../../Model/document.model';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-document-modal',
  templateUrl: './add-document-modal.component.html',
  styleUrls: ['./add-document-modal.component.scss'],
})
export class AddDocumentModalComponent implements OnInit {

  type: any;
  id: any;
  searchDocuments: any;
  length = 0;
  query: any[] = [];
  whereDoc: any = {};
  documents: any[] = [];

  idDocType = 2;
  document: Document = {};
  doc: any;
  docName: any;
  exist = false;
  selected: any;
  path: any = '';
  pdfIcon = '../../../assets/icon/pdf.png';
  docIcon = '../../../assets/icon/doc.png';
  docxIcon = '../../../assets/icon/docx.png';
  anyIcon = '../../../assets/icon/any-doc.png';



  constructor(
    private docService: DocumentService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  selectFile(event: any) {
    this.doc = event.target.files[0];
    this.docName = event.target.files[0].name;
    const extension = this.doc.name.substring(this.doc.name.lastIndexOf('.'));

    if (event){
      this.exist = true;
    }

    if (extension.includes('.jp' || '.png' || '.heic' || '.bmp')) {
      const reader = new FileReader();
      reader.readAsDataURL(this.doc);
      reader.onload = (_event) => {
        this.path = reader.result;
      };

    } else {
      switch (extension) {
        case '.pdf':
          this.path = this.pdfIcon;
          break;
        case '.doc':
          this.path = this.docIcon;
          break;
        case '.docx':
          this.path = this.docxIcon;
          break;
        default:
          this.path = this.anyIcon;
          break;
      }
    }
  }

  uploadDoc() {
    this.modalCtrl.dismiss({
      doc: this.doc,
      documentType: this.idDocType
    });
  };

  back() {
    this.modalCtrl.dismiss();
  }
}
