import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoginService } from '../../services/login.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  open = true;
  email = '';
  identifier = '';

  constructor(
    private modalCtrl: ModalController,
    private loginService: LoginService,
    private ui: UiService
  ) { }

  ngOnInit() { }

  back() {
    this.modalCtrl.dismiss();
  }

  async onSubmit() {
    const register = await this.loginService.register(this.email, this.identifier);
    if (!register.ok) {
      this.email = '';
      this.identifier = '';
      return this.ui.alertError('Sus datos de contacto no están en la base de datos, pongase en contacto con la Hermandad.');
    }
    this.ui.alertInfo('Solicitud enviada, recibirá un correo electrónico con los datos de acceso.', '');
    this.back();
  }




}
