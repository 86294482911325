import { Component, OnInit, ViewChild } from '@angular/core';
import { BrotherDataService } from '../../services/portalServices/brother-data.service';
import { UiService } from '../../services/ui.service';
import { ModalController} from '@ionic/angular';

@Component({
  selector: 'app-update-payment-modal',
  templateUrl: './update-payment-modal.component.html',
  styleUrls: ['./update-payment-modal.component.scss'],
})
export class UpdatePaymentModalComponent implements OnInit {
  @ViewChild('popover') popover;
  
  isOpen = false;
  editable = false;
  local_data_bpm: any = {};
  maritalStatus: any = [];
  id: any;
  toolTipText = 'Ingrese el IBAN sin espacios, comas, puntos, o cualquier otro caracter especial.';
  ppList: any = [];

  constructor(
    private bds: BrotherDataService,
    private ui: UiService,
    private modalCtrl: ModalController,
    ) { }


  ngOnInit() {
    this.loadBrotherPMData();
    this.loadPaymentPeriods();
    this.presentPopover();
  }

  async loadBrotherPMData() {
    const bpmd = await this.bds.getBrotherPaymentMethod();
    if (!bpmd.ok) {
      return window.alert(bpmd.error);
    }
    this.local_data_bpm = bpmd.paymentMethod || {};
  }

  async updateBrotherPMData() {
    const brotherPM = this.local_data_bpm;
    brotherPM.idDirectoryEntry = this.id;
    brotherPM.feeType = 1;
    brotherPM.idPaymentMethod = 1;
    const updResp = await this.bds.setBrotherPaymentMethod(brotherPM);
    if (!updResp.ok || brotherPM.iban === '') { return this.ui.alertInfo('El IBAN no es correcto. Revíselo por favor.', 'Pulse en "Indicaciones" para evitar errores.'); };
    this.ui.alertInfo('Datos actualizados correctamente', 'El cambio en la periodicidad será efectivo en la próxima emisión de cuotas');
    this.loadBrotherPMData();
    this.editable = false;
    this.modalCtrl.dismiss();
  }

  async loadPaymentPeriods() {
    const loadPP: any = await this.bds.getPaymentPeriods();
    if (!loadPP.ok) {return this.ui.alertInfo('Error al consultar las periodicidades de pago. Consulte con la Hermandad.', '');}
    this.ppList = loadPP.paymentPeriods || [];    
  }

  // Show ToolTip
  async presentPopover() {
    const popover: any = document.querySelector('.popoverr');
    const popoverBtn: any = document.querySelector('#popoverBtn');
    
    popoverBtn.addEventListener('click', () => {
      popover.classList.add('is-visible');
      setTimeout( () => {
        popover.classList.remove('is-visible');
      }, 4000);
    });    
  }

  back() {
    this.modalCtrl.dismiss();
  }

}
