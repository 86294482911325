import { Component, OnInit, Input, Output } from '@angular/core';
import { DataService } from '../../../../services/data.service';

@Component({
  selector: 'app-brother-button-access',
  templateUrl: './brother-button-access.component.html',
  styleUrls: ['./brother-button-access.component.scss'],
})
export class BrotherButtonAccessComponent implements OnInit {

  @Input() data: any = {};
  @Output() selected: any;
  imgUrl: any;

  constructor(private ds: DataService) { }

  async ngOnInit() {
    //TODO: DESCOMENTAR CUANDO SE PREPARE LA IMAGEN
    // const imgResp: any = await this.ds.downloadImagenPerfilId(this.data.brother.id);
    // const reader = new FileReader();
    // reader.readAsDataURL(imgResp);
    // reader.onload = () => {
    //   this.imgUrl =  reader.result;
    // }
  }

}
