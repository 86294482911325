import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalServiceService {

  constructor() { }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private fooSubject = new Subject<any>();

    publishSomeData(data: any) {
        this.fooSubject.next(data);
    }

    getObservable(): Subject<any> {
        return this.fooSubject;
    }
}
