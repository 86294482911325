import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { appData, brotherhoodData, globalUser } from '../../../environments/globales';
import { RedsysService } from '../../services/portalServices/redsys.service';
import { environment } from '../../../environments/environment.prod';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { UiService } from '../../services/ui.service';
import { LegalModalComponent } from '../legal-modal/legal-modal.component';
import * as uuid from 'uuid';

@Component({
  selector: 'app-confirmation-new-brother-modal',
  templateUrl: './confirmation-new-brother-modal.component.html',
  styleUrls: ['./confirmation-new-brother-modal.component.scss'],
})
export class ConfirmationNewBrotherModalComponent implements OnInit, OnDestroy {

  @Input() data: any = [];
  @Input() formData: any = [];
  needPay = false;
  opneFrom = '/redsysPay/openformTest/';
  win: any;
  interval: any;
  intervalEnd: any;
  real = false;
  debt: any = {};
  process = false;
  end = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  local_data: any;
  agreeLopdPTS: boolean;
  constructor(
    private modalCtrl: ModalController,
    private rs: RedsysService,
    private iab: InAppBrowser,
    private ui: UiService
  ) {
    if (brotherhoodData.mode === 1) {
      this.real = true;
      this.opneFrom = '/redsysPay/openform/';
    }
  }


  ngOnInit(): void {
    if (appData.newBrotherFee > 0 || appData.newBrotherFeeYounger > 0) {
      this.needPay = true;
      this.intervalEnd = setInterval(async () => {
        const resp = await this.rs.getOrderPayPts(this.debt.id);
        if (!resp.ok && resp.order.payed) {
          clearInterval(this.intervalEnd);
          this.end = true;
          this.modalCtrl.dismiss({ end: true, idDebt: this.debt.id });
        }
      }, 1000);
    }
  }

  back() {
    this.modalCtrl.dismiss({ end: false });
  }

  getYearOld(birthDate: Date): number {
    const date = new Date();
    const bd = new Date(birthDate);
    let yearOld = date.getFullYear() - bd.getFullYear();
    if (date.getMonth() < bd.getMonth()) { yearOld--; }
    else if (date.getMonth() === bd.getMonth()) {
      if (date.getDate() < bd.getDate()) { yearOld--; }
    }
    return yearOld;
  }

  async sendApplication() {
    if (this.needPay) {
      const idTemp = uuid.v4();
      const date = new Date();
      let amount = appData.newBrotherFee;
      if (this.getYearOld(this.data.brother.birthDate) < 18) {
        amount = appData.newBrotherFeeYounger;
      }
      const ptsDebt = {
        idTemp,
        concept: appData.newBrotherConcept,
        amount,
        reminderAmount: amount,
        debtDate: date,
        dueDate: date,
        payed: false,
        idPaymentMethod: 5,
        idDirectoryEntry: globalUser.id
      };

      const order = await this.rs.createOrderPayNoDebt(ptsDebt);
      if (!order.ok) {
        return window.alert('Error al generar la pasarela de pago');
      }

      const dataPay = {
        id: order.order.id,
        concept: appData.newBrotherConcept,
        amount,
        reminderAmount: amount,
        debtDate: date,
        dueDate: date,
        payed: false,
        idPaymentMethod: 5,
      };
      this.debt.id = dataPay.id;
      this.openModal(dataPay.id);
    } else {
      this.modalCtrl.dismiss({ end: true });
    }
  }

  async openModal(debtId: number) {
    this.win = this.iab.create(environment.backendUrl +
      this.opneFrom + debtId,
      '_blank', 'location=no ,enableViewportScale=yes, closebuttoncaption=cerrar, zoom=yes, enableViewportScale=yes');

    this.interval = setInterval(async () => {
      const resp = await this.rs.getOrderPayPts(debtId);
      if (this.win?.closed) {
        clearInterval(this.interval);
      }
      if (resp.ok) {
        this.process = true;
        this.win?.close();
        clearInterval(this.interval);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const dataModal = { id: resp.order.idDebt, MD: resp.order.MD, order: resp.order.idOrder };

        if (this.real) {
          this.rs.setPaymentRedsys(dataModal.id, dataModal.MD, dataModal.order).then(async respPay => {
            switch (respPay.type) {
              case 1:
                this.rs.redsysPay(dataModal.id, dataModal.MD, dataModal.order, respPay.buff).then(async respPay1 => {
                  if (respPay1.ok && respPay1.respTrata.response === 0) {
                    this.ui.alertInfo('Pago realizado correctamente', 'ok');
                    this.modalCtrl.dismiss({ end: true, idDebt: this.debt.id });
                  } else if (respPay.ok && respPay1.respTrata.emv3ds) {
                    const dataResp = respPay1.respTrata.emv3ds;
                    dataResp.idOrder = dataModal.order;
                    await this.openAuth(dataResp);
                  }
                });
                break;
              case 2: // V: 2.1.0

                this.rs.redsysPay(dataModal.id, dataModal.MD, dataModal.order, respPay.buff).then(async respPay1 => {
                  if (respPay1.ok && respPay1.respTrata.response === 0) {
                    window.alert('Pago realizado correctamente');
                    this.modalCtrl.dismiss({ end: true, idDebt: this.debt.id });
                  } else if (respPay1.ok && respPay1.respTrata.emv3ds) {
                    const dataResp = respPay1.respTrata.emv3ds;
                    dataResp.idOrder = dataModal.order;
                    await this.openAuth(dataResp);
                  }
                });
                break;

              case 3:
                break;
            }

          });
        } else {
          this.rs.setPaymentRedsysTest(dataModal.id, dataModal.MD, dataModal.order).then(respPay => {
            switch (respPay.type) {
              case 1:
                this.rs.redsysPayTest(dataModal.id, dataModal.MD, dataModal.order, respPay.buff).then(async respPay1 => {
                  if (respPay1.ok && respPay1.respTrata.response === 0) {
                    window.alert('Pago realizado correctamente');
                    this.modalCtrl.dismiss({ end: true, idDebt: this.debt.id });
                  } else if (respPay.ok && respPay1.respTrata.emv3ds) {
                    const dataResp = respPay1.respTrata.emv3ds;
                    dataResp.idOrder = dataModal.order;
                    await this.openAuth(dataResp);
                  }
                });
                break;

              case 2: // V: 2.1.0
                const navigatorData = {
                  navigator: window.navigator.userAgent.toLowerCase(),
                  language: window.navigator.language,
                  heigth: window.screen.height.toString(),
                  width: window.screen.width.toString(),
                  colorDepth: window.screen.colorDepth.toString(),
                  timeZone: new Date().getTimezoneOffset()
                };

                this.rs.redsysPayTest(
                  dataModal.id, dataModal.MD, dataModal.order, respPay.buff, navigatorData).then(async respPay1 => {
                    if (respPay1.ok && respPay1.respTrata.response === 0) {
                      window.alert('Pago realizado correctamente');
                      this.end = true;
                      this.modalCtrl.dismiss({ end: true, idDebt: this.debt.id });
                    } else if (respPay.ok && respPay1.respTrata.emv3ds) {
                      const dataResp = respPay1.respTrata.emv3ds;
                      dataResp.idOrder = dataModal.order;
                      await this.openAuth(dataResp);
                    }
                  });
                break;
            }
          });
        }
      }
    }, 1000);
  }

  async openAuth(dataResp: any) {
    const html = `
    <html>
    <body>
    <form name="sendData" method="POST" action="${dataResp.acsURL}"
    target="iframe_3DS_Challenge" enctype="application/xwww-form-urlencoded" style="display: none">
        <input name="TermUrl" value="${dataResp.termUrl}">
        <input name="MD" value="${dataResp.MD}">
        <input name="CReq" value="${dataResp.creq}">
    </form>
    <iframe name="iframe_3DS_Challenge" src="" class="iframe_3DS_Challenge" style="height: 100%; width: 100%; border: 0"></iframe>
    </body>

    </html>

    <script type="text/javascript">
      window.onload = function() {
        document.forms["sendData"].submit();
      }
    </script>`;
    const pageContent = 'data:text/html;base64,' + btoa(html);
    const auth = this.iab.create(
      pageContent, '_blank', 'hidden=no,location=no,clearsessioncache=yes,clearcache=yes');

    auth.executeScript({ code: 'document.getElementById("boton").click();' }).then(() => {
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalEnd);
    if (!this.end) {
    }
    if (this.win) {
      this.win.close();
    }
  }

  async legal() {
    const modal = await this.modalCtrl.create({
      component: LegalModalComponent,
      cssClass: 'legal-modal',
      backdropDismiss: false,
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    this.agreeLopdPTS = data.lopd;
  }
}
