import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-read-meetings-modal',
  templateUrl: './read-meetings-modal.component.html',
  styleUrls: ['./read-meetings-modal.component.scss'],
})
export class ReadMeetingsModalComponent implements OnInit {

  @Input() met: any = {};

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  back(){
    this.modalCtrl.dismiss();
  }

}
