import { Component, OnInit } from '@angular/core';
import { ModalController, ActionSheetController, Platform } from '@ionic/angular';
import { environment } from '../../../environments/environment.prod';



@Component({
  selector: 'app-read-news',
  templateUrl: './read-news.component.html',
  styleUrls: ['./read-news.component.scss'],
})
export class ReadNewsComponent implements OnInit {
  news: any = {};
  url = environment.backendUrl;
  constructor(private modalCtrl: ModalController,
    private actionSheetController: ActionSheetController, private platform: Platform) { }

  ngOnInit() {}

  async onShare(){
    const actionSheet = await this.actionSheetController.create({
      buttons: [{
        text: 'Compartir',
        icon: 'share',
        handler: () => {
          this.shareNews();
      }},
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        cssClass: 'action-dark',
        handler: () => {
        }
      }]
    });
    await actionSheet.present();
  }

  shareNews(){
     // TODO: Preparar noticia en backend para publicar
  //   if (this.platform.is('cordova')) {
  //     this.socialSharing.share(
  //       this.news.title,
  //       'Noticia compartida desde Ghercof APP',
  //       '',
  //       this.news.content
  //     );
  // }
}

  back(){
    this.showToggle();
    this.modalCtrl.dismiss();
  }
showToggle() {
    const toggleId = document.querySelector('#toggleComponent');
    toggleId.classList.remove('hide-toggle');
    const tabMenu = document.querySelector('#tabPral');
    tabMenu.classList.remove('hide-menu');
  }

}
