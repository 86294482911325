import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanPipe'
})
export class BooleanPipe implements PipeTransform {

  transform(value: boolean): string {
    switch(value) {
      case true:
        return 'Sí';
      case false:
        return 'No';
      default:
        return value;
    }
  }
}