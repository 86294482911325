import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { platformRun, globalUser, brotherhoodData } from '../../../environments/globales';
import { GlobalServiceService } from '../../services/global-service.service';

@Component({
  selector: 'app-tabs-pral',
  templateUrl: './tabs-pral.component.html',
  styleUrls: ['./tabs-pral.component.scss'],
})
export class TabsPralComponent implements OnInit {

  textOption = 'Ver +';
  hiden = false;
  bbc = false;
  user = globalUser;
  secundaryMenu = true;
  youtubeChanel = brotherhoodData.youtubeChanel;
  active = false;


  constructor(
    private router: Router,
    private gs: GlobalServiceService,
    private iab: InAppBrowser
  ) { }

  ngOnInit() {
    if (platformRun.ios && platformRun.heightPlatform >= 812) {
      const firstMenu: any = document.querySelector('.navbar');
      const footer: any = document.querySelector('#footer');
      const footerImg: any = document.querySelector('#footerImg');
      const secondaryMenu: any = document.querySelector('.secondary-menu-container');

      firstMenu.classList.add('navbar-ios');
      footer.classList.add('footer-ios');
      footerImg.classList.remove('footer-img-ios');
      secondaryMenu.classList.add('secondary-menu-container-ios');
    }
    if (this.youtubeChanel && this.youtubeChanel !== ''){
      this.active = true;
    }
    //this.activeSecondaryMenu();
    this.activeMenuItem('');
    }

    // Se ejecuta siempre despues de cargar la pag.
  //   ionViewDidEnter() {
  //     this.activeSecondaryMenu();
  // }

  activeSecondaryMenu() {
    if (this.secundaryMenu) {
      this.hidenMenu();
    } else {
      this.showMenu();
    }
  }

  activeMenuItem(item: string) {
    this.bbc = true;
    if (item === 'yt'){
      const win = this.iab.create(this.youtubeChanel,
        '_blank', 'location=no ,enableViewportScale=yes, closebuttoncaption=cerrar, zoom=yes, enableViewportScale=yes');
    } else {
      const menuItem = document.querySelectorAll('.menu-icon');
      menuItem.forEach((element) => {
        element.addEventListener('click', function() {
          menuItem.forEach((nav) => nav.classList.remove('is-active'));
          this.classList.add('is-active');
        });
      });
      if (item){
        this.router.navigate(['/' + item]);
      }
        this.showMenu();
    }

  }

  showMenu() {
    this.secundaryMenu = true;
    const secondaryMenu: any = document.querySelector('.secondary-menu-container');
    const optionText = document.querySelector('.text-option');
    const plusIcon = document.querySelector('.plusIcon');
    const lessIcon = document.querySelector('.lessIcon');
    secondaryMenu.classList.remove('show-menu');
    optionText.textContent = 'Ver +';
    plusIcon.classList.remove('display-icon');
    lessIcon.classList.add('display-icon');
  }

  hidenMenu() {
    this.secundaryMenu = false;
    const secondaryMenu: any = document.querySelector('.secondary-menu-container');
    const optionText = document.querySelector('.text-option');
    const plusIcon = document.querySelector('.plusIcon');
    const lessIcon = document.querySelector('.lessIcon');

    secondaryMenu.classList.add('show-menu');
    optionText.textContent = 'Ver -';
    plusIcon.classList.add('display-icon');
    lessIcon.classList.remove('display-icon');
  }

  displayMenu() {
    const navbar = document.querySelector('.navbar');
    const btnIcon = document.querySelector('.displayMenuBtn');
    const secondaryMenu = document.querySelector('.secondary-menu-container');

    btnIcon.classList.toggle('btnRotate');
    btnIcon.classList.toggle('btnPosition');
    navbar.classList.toggle('menu-visible');

    if (secondaryMenu) {
      secondaryMenu.classList.toggle('menu-left');
    }
    this.activeSecondaryMenu();
  }
}
