import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-read-notification',
  templateUrl: './read-notification.component.html',
  styleUrls: ['./read-notification.component.scss'],
})
export class ReadNotificationComponent implements OnInit {

  @Input()data: any = {};
  content = '';
  constructor(private modalCtrl: ModalController,
              private ns: NotificationService) { }

  async ngOnInit() {
    this.content = await this.ns.getNotificationId(this.data.id);
  }

  back(){
    this.modalCtrl.dismiss();
  }

}
