import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalServiceService } from '../../services/global-service.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})

export class BackButtonComponent {


  constructor(private router: Router, private gs: GlobalServiceService) { }


  backToHome(){
    this.gs.publishSomeData({button: false});
    this.router.navigate(['/']);
  }


}
