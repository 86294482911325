import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-data-procession',
  templateUrl: './no-data-procession.component.html',
  styleUrls: ['./no-data-procession.component.scss'],
})
export class NoDataProcessionComponent implements OnInit {

  @Input()text = '';
  constructor() { }

  ngOnInit() {}

}
