import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiService } from '../../services/ui.service';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-unsubscribe-modal',
  templateUrl: './unsubscribe-modal.component.html',
  styleUrls: ['./unsubscribe-modal.component.scss'],
})
export class UnsubscribeModalComponent implements OnInit {

  toolTipText = 'Indique de cuál servicio quiere darse de baja y el motivo.';
  unsuscribeReason = '';
  unsuscribe: any;


  constructor(
    private modalCtrl: ModalController,
    private ui: UiService,
    private router: Router,
    private loginService: LoginService
  ) {
  }

  ngOnInit() {
    this.showIndications();
  }

  back() {
    this.modalCtrl.dismiss();
  }

  // Show ToolTip
  async showIndications() {
    const popover: any = document.querySelector('.popoverr');
    const popoverBtn: any = document.querySelector('#popoverBtn');

    popoverBtn.addEventListener('click', () => {
      popover.classList.add('is-visible');
      setTimeout(() => {
        popover.classList.remove('is-visible');
      }, 4000);
    });
  }

  // Get the value from Ion-Select
  onSelectChange(event: any) {
      this.unsuscribe = event;
  };


  send() {
    const confirm = window.confirm('¿Está seguro de que quiere darse de baja?');
    if (confirm){
      this.modalCtrl.dismiss({ reason: this.unsuscribeReason = this.unsuscribeReason, unsuscribe: this.unsuscribe });
    }
  }

  activeSend(){
    if (this.unsuscribe === 'true' && this.unsuscribeReason.length < 4){
      return true;
    }else if (this.unsuscribe === 'true' && this.unsuscribeReason.length >= 4){
      return false;
    } else if (this.unsuscribe === 'false'){
      return false;
    } else {
      return true;
    }
  }
}
