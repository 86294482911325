import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { DocumentResp, DocTypeResp } from '../../Model/document.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private ds: DataService) { }

  page = 0;

  getDocuments(){
      this.page++;
       return new Promise<DocumentResp>(resolve => {
           this.ds.getData(`ph/documents/get?page=${this.page}`).then((resp: DocumentResp) => {
             resolve(resp);
           });
         });
  }

  getDocumentsType(type: string) {
       return new Promise<DocTypeResp>(resolve => {
         this.ds.getData('documents/type?type=' + type).then(resp => {
           resolve(resp);
         });
       });
  }

}
