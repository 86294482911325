import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-load-asociate-brother',
  templateUrl: './load-asociate-brother.component.html',
  styleUrls: ['./load-asociate-brother.component.scss'],
})
export class LoadAsociateBrotherComponent implements OnInit {

  @Input() data;
  safetyCheck: string = '';
  password: string = '';
  active: boolean = false;
  green = false;
  bordeaux = false;
  purple = false;
  blue = false;
  black = false;
  hide: boolean = true;
  hideSafety: boolean = true;
  urlProfileImg: any;
  brotherRel: any[] = [];

  constructor(private modalCtrl: ModalController) { }

  async ngOnInit() { await this.loadBrotherRel(); }

  async loadBrotherRel() {
    if (this.data && this.data.relations) {
      for await (const d of this.data.relations) {
        if (d.brother?.entityType == 'brother') this.brotherRel.push(d);
      }
      //TODO: No le da tiempo a cargarlo, revisar en el futuro.
      // if (this.data.brother.entityType != 'brother' && this.brotherRel.length == 1) {
      //   await this.modalCtrl.dismiss(this.brotherRel[0].idDirectoryEntry2);
      // }
    }
  }

  select(d: any){
    this.modalCtrl.dismiss(d.idDirectoryEntry2);
  }

  closeDialog(){
    this.modalCtrl.dismiss();
  }

}
