import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-groups-and-relations',
  templateUrl: './no-groups-and-relations.component.html',
  styleUrls: ['./no-groups-and-relations.component.scss'],
})
export class NoGroupsAndRelationsComponent implements OnInit {

  @Input()text = '';
  constructor() { }

  async ngOnInit() {}
}
