import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {

  @Input() data: any;
  local_data: any = {};

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    if (this.data) this.local_data = this.data;
  }

  closeDialog() {
    this.modalCtrl.dismiss();
  }

}
