import { Component, Input, OnInit } from '@angular/core';
import { GaleryService } from '../../services/galery.service';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'app-img-galery-album',
  templateUrl: './img-galery-album.component.html',
  styleUrls: ['./img-galery-album.component.scss'],
})
export class ImgGaleryAlbumComponent implements OnInit {

  @Input()idAlbum: any;
  url = environment.backendUrl;
  galeryItem = '';
  constructor(private gs: GaleryService) { }

  ngOnInit() {
    setTimeout(async () => {
      await this.getFistItem();
    }, 200);
  }

  async getFistItem(){

    const gis = await this.gs.getGaleryItems(this.idAlbum);
    if (gis[0]){
      this.galeryItem = `${this.url}/galeryItem/item/${gis[0].fileName}`;
    } else {
      this.galeryItem = '../../../assets/img/heraldica.png';
    }
  }

}
