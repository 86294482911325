/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';

import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { ModalController } from '@ionic/angular';
import { BrotherDataService } from 'src/app/services/portalServices/brother-data.service';
import { LoginService } from '../../services/login.service';
import { UiService } from '../../services/ui.service';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { DataService } from '../../services/data.service';
import { GlobalServiceService } from '../../services/global-service.service';
import { globalUser } from '../../../environments/globales';
import { Router } from '@angular/router';

@Component({
  selector: 'app-options-modal',
  templateUrl: './options-modal.component.html',
  styleUrls: ['./options-modal.component.scss'],
})
export class OptionsModalComponent implements OnInit {

  exitEnabled = false;
  active = false;
  local_data: any = { address: {} };
  urlProfileImg: any;
  user = globalUser;

  constructor(
    private modalCtrl: ModalController,
    private loginService: LoginService,
    private ui: UiService,
    private router: Router,
    private bds: BrotherDataService,
    private camera: Camera,
    private ds: DataService,
    private gs: GlobalServiceService,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    const respBrotherData = await this.bds.getBrotherData();
    this.local_data = respBrotherData.brother;
  }

  async logout() {
    const confirm = await this.ui.presentConfirm('¿Desea salir de la sesión del Portal del Hermano/a?');
    if (confirm) {
      this.loginService.logout().then(resp => {
        if (resp) {
          this.exitEnabled = false;
          this.router.navigate(['']);
          this.active = false;
          this.user.id = null;
        }
      });
      this.modalCtrl.dismiss();
      this.gs.publishSomeData({ togglePublic: true, activeToggle: true });
      this.router.navigate(['/']);
    }
  }

  async changePassword() {
    const modal = await this.modalCtrl.create({
      component: ChangePasswordModalComponent,
      backdropDismiss: false,
      mode: 'ios',
    });
    await modal.present();
    await modal.onDidDismiss().then(async (data: any) => {
      if (!data.data){ return this.modalCtrl.dismiss();}
      this.local_data.password = data.data.password;
      const updateData = await this.bds.updateDataBrother(this.local_data);
      if (!updateData.ok) {
        return this.ui.alertError('Ha ocurrido un error al actualizar la contraseña. Inténtelo de nuevo o contacte con la Hermandad.');
      }
      this.ui.alertInfo('Contraseña modificada correctamente', 'OK');
      this.modalCtrl.dismiss();
    });
    this.loadData();
  };

  async openCamera() {
    const options: CameraOptions = {
      quality: 60,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      sourceType: this.camera.PictureSourceType.CAMERA
    };

    this.imageProcessing(options);
  }

  openGallery() {
    const options: CameraOptions = {
      quality: 60,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY
    };
    this.imageProcessing(options);
  }

  async imageProcessing(options: CameraOptions) {
    this.camera.getPicture(options).then(async (imageData) => {
      this.urlProfileImg = 'data:image/png;base64, ' + imageData;
      const resp = await this.ds.uploadImageProfile(this.urlProfileImg);
      if (!resp.ok){
        return this.ui.alertError(resp.error);
      }
      this.ui.presentToast('Imagen cargada correctamente');
      this.modalCtrl.dismiss();
    }, (err) => {
      // Handle error
      this.modalCtrl.dismiss();
      this.ui.alertInfo('No se ha podido subir la fotografía', '');
    });
  }

}
