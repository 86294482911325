import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routesApp: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'portal-tabs',
    loadChildren: () => import('./portal-tabs/portal-tabs.module').then(m => m.PortalTabsPageModule)
  },
  {
    path: 'brotherhood',
    loadChildren: () => import('./tabs/brotherhood/brotherhood.module').then(m => m.BrotherhoodPageModule)
  },
  {
    path: 'rector',
    loadChildren: () => import('./tabs/brotherhood/pages/rector/rector.module').then(m => m.RectorPageModule)
  },
  {
    path: 'titulares',
    loadChildren: () => import('./tabs/titulares/titulares.module').then(m => m.TitularesPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./tabs/notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'cults',
    loadChildren: () => import('./tabs/cults/cults.module').then(m => m.CultsPageModule)
  },
  {
    path: 'social-assistance',
    loadChildren: () => import('./tabs/social-assistance/social-assistance.module').then(m => m.SocialAssistancePageModule)
  },
  {
    path: 'donations',
    loadChildren: () => import('./tabs/donations/donations.module').then(m => m.DonationsPageModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./tabs/gallery/gallery.module').then(m => m.GalleryPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./tabs/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./tabs/news/news.module').then(m => m.NewsPageModule)
  },
  {
    path: 'governors-board',
    loadChildren: () => import('./tabs/brotherhood/pages/governors-board/governors-board.module').then(m => m.GovernorsBoardPageModule)
  },
  {
    path: 'big-brother',
    loadChildren: () => import('./tabs/brotherhood/pages/big-brother/big-brother.module').then(m => m.BigBrotherPageModule)
  },
  {
    path: 'rules',
    loadChildren: () => import('./tabs/brotherhood/pages/rules/rules.module').then(m => m.RulesPageModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('./tabs/brotherhood/pages/groups/groups.module').then(m => m.GroupsPageModule)
  },
  {
    path: 'heraldry',
    loadChildren: () => import('./tabs/brotherhood/pages/heraldry/heraldry.module').then(m => m.HeraldryPageModule)
  },
  {
    path: 'seats',
    loadChildren: () => import('./tabs/brotherhood/pages/seats/seats.module').then(m => m.SeatsPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./tabs/brotherhood/pages/history/history.module').then(m => m.HistoryPageModule)
  },
  {
    path: 'brother',
    loadChildren: () => import('./portal-tabs/brother/brother.module').then( m => m.BrotherPageModule)
  },
  {
    path: 'procesion',
    loadChildren: () => import('./portal-tabs/procesion/procesion.module').then( m => m.ProcesionPageModule)
  },
  {
    path: 'economic',
    loadChildren: () => import('./portal-tabs/economic/economic.module').then( m => m.EconomicPageModule)
  },
  {
    path: 'correspondence',
    loadChildren: () => import('./portal-tabs/correspondence/correspondence.module').then( m => m.CorrespondencePageModule)
  },
  {
    path: 'meeting',
    loadChildren: () => import('./portal-tabs/meeting/meeting.module').then( m => m.MeetingPageModule)
  },
  {
    path: 'payment-method',
    loadChildren: () => import('./portal-tabs/brother/pages/payment-method/payment-method.module').then( m => m.PaymentMethodPageModule)
  },
  {
    path: 'documents',
    loadChildren: () => import('./portal-tabs/brother/pages/documents/documents.module').then( m => m.DocumentsPageModule)
  },
  {
    path: 'group-and-family',
    // eslint-disable-next-line max-len
    loadChildren: () => import('./portal-tabs/brother/pages/group-and-family/group-and-family.module').then( m => m.GroupAndFamilyPageModule)
  },
  {
    path: 'model182',
    loadChildren: () => import('./portal-tabs/brother/pages/model182/model182.module').then( m => m.Model182PageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routesApp, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
