import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BrotherDataService } from '../../services/portalServices/brother-data.service';
import { UiService } from '../../services/ui.service';
import { ConfirmationNewBrotherModalComponent } from '../confirmation-new-brother-modal/confirmation-new-brother-modal.component';
import * as IBAN from 'iban';
import { BrotherPaymentMethod } from '../../Model/brother.model';
import { appData } from '../../../environments/globales';

@Component({
  selector: 'app-new-brother-modal',
  templateUrl: './new-brother-modal.component.html',
  styleUrls: ['./new-brother-modal.component.scss'],
})
export class NewBrotherModalComponent implements OnInit {


  totalData = [];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  local_data: any = { address: {} };
  brotherPM: BrotherPaymentMethod = {};


  // Variables para el Select - Newsletter:

  newsletters: any = [
    { value: true, viewValue: 'Sí' },
    { value: false, viewValue: 'No' },
  ];

  // Variables para el Select - Género
  genders: any = [
    { value: 'M', viewValue: 'Masculino' },
    { value: 'F', viewValue: 'Femenino' },
  ];

  // Variables MaritalStatusId

  maritalStatusSelected: any = [
    { value: 1, viewValue: 'Casado' },
    { value: 2, viewValue: 'Soltero' },
    { value: 3, viewValue: 'Separado' },
    { value: 4, viewValue: 'Viudo' }
  ];

  // Payment Periods
  payPeriods: any = [
    { name: 'Anual', value: 1 },
    { name: 'Trimestral', value: 2 }
  ];

  // Variables titular de la cuenta:
  accountHolder: any = [
    { value: 0, viewValue: 'Sí' },
    { value: 1, viewValue: 'No' },
    {
      value: 2,
      viewValue: 'No, pero el titular ya tiene relación con la Hermandad',
    },
  ];

  // Upload Document Variables
  doc1: any;
  doc1Name: any;
  doc1Path: any;
  doc2: any;
  doc2Name: any;
  doc2Path: any;
  exist1 = false;
  exist2 = false;
  pdfIcon = '../../../assets/icon/pdf.png';
  docIcon = '../../../assets/icon/doc.png';
  docxIcon = '../../../assets/icon/docx.png';
  anyIcon = '../../../assets/icon/any-doc.png';
  page = 1;

  // Brother and Form Variables
  brother: any = {};
  accountOwnerSelected: any;

  // Variables para el método de Pago:
  payMethod: any = { value: 1, viewValue: 'Banco' };

  // Form Variables:
  firstForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;
  fourthForm: FormGroup;
  fifthForm: FormGroup;
  sixthForm: FormGroup;
  seventhForm: FormGroup;

  // Need Pay
  needPay = false;

  constructor(
    private bds: BrotherDataService,
    private ui: UiService,
    private modalCtrl: ModalController,
    private fb: FormBuilder,
  ) {

    // FORM CONTROL - NEW BROTHER
    // First Step - Personal Data
    this.firstForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: [''],
      email: ['', [Validators.required, Validators.email]],
      identifier: ['', [
        Validators.minLength(9),
        Validators.maxLength(15),
      ]],
      birthDate: [(new Date()).toISOString()],
      telephone1: ['', [
        Validators.required,
        Validators.minLength(9),
      ]],
      gender: ['',],
      idMaritalStatus: [''],
      newsletter: [true],
    });

    // Second Step - Baptism Data
    this.secondForm = this.fb.group({
      baptizedIn: [''],
      baptismDate: [(new Date()).toISOString(), [Validators.required]],
      parish: [''],
    });

    // Third Step - Address
    this.thirdForm = this.fb.group({
      address: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      cp: ['', [Validators.required]],
      country: new FormControl({ value: '', disabled: true }),
      province: new FormControl({ value: '', disabled: true }),
      location: new FormControl({ value: '', disabled: true }),
      ca: new FormControl({ value: '', disabled: true }),
    });

    // Fourth Step - Payments
    this.fourthForm = this.fb.group({
      idPaymentMethod: new FormControl({ value: '', disabled: true }),
      idPaymentPeriod: ['', [Validators.required]],
      iban: ['', [Validators.required, Validators.minLength(9)]],
      accountOwner: [''],
    });

    // Fifth Step - Collaborator Payment
    this.fifthForm = this.fb.group({
      bankOwnerName: ['', [Validators.required]],
      bankOwnerFirstName: ['', [Validators.required]],
      ownerDni: ['', [Validators.required]],
      ownerAddress: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      cpOwner: ['', [Validators.required]],
      ownerCountry: new FormControl({ value: '', disabled: true }),
      ownerProvince: new FormControl({ value: '', disabled: true }),
      ownerLocation: new FormControl({ value: '', disabled: true }),
      ownerCa: new FormControl({ value: '', disabled: true }),
      ownerEmail: ['', [Validators.required, Validators.email]],
      ownerTelephone: ['', [Validators.required]],
    });

    // Sixth Step - DNI of Collaborator Payment
    this.sixthForm = this.fb.group({
      ownerDni: ['', [Validators.required]],
    });
    // Seventh Step - DNI of Collaborator Payment
    this.seventhForm = this.fb.group({
      doc1: ['', [Validators.required]],
      doc2: ['', [Validators.required]]
    });
  }

  async ngOnInit() {
    if (appData.newBrotherFee > 0 || appData.newBrotherFeeYounger > 0) {
      this.needPay = true;
    }
   }

  // Submit del Formulario:
  async onFormSubmit(idDebt?: number) {
    this.fourthForm.value.idPaymentMethod = 1;
    const formData = {
      doc1: this.doc1,
      doc2: this.doc2,
      brother: {
        ...this.firstForm.value,
        ...this.secondForm.value,
        ...this.local_data,
      },
      paymentMethod: { ...this.fourthForm.value },
      payData: idDebt || null,
      agreeLopdPTS: true
    };

    if (this.fourthForm.value.accountOwner === 1) {
      formData.paymentMethod = {
        ...this.fourthForm.value,
        ...this.fifthForm.value,
      };
    }

    if (this.fourthForm.value.accountOwner === 2) {
      formData.paymentMethod = {
        ...this.fourthForm.value,
        ...this.sixthForm.value,
      };
    }

    const setResp = await this.bds.setBrotherApplication(formData);
    if (!setResp.ok) {return window.alert(setResp.error);}
    this.ui.alertInfo('Solicitud dada de alta con éxito', 'OK');
    this.modalCtrl.dismiss(true);
  }

  enviarDatos() {
    const datos = {
      brother: {
        ...this.firstForm.value,
        ...this.secondForm.value,
        ...this.local_data,
      },
      paymentMethod: { ...this.fourthForm.value },
      documents: {
        doc1: this.doc1,
        doc2: this.doc2
      }

    };
    return datos;
  }

  // Control del Formulario de Nuevo Hermano:
  //Error para email:
  getErrorMessage() {
    return this.firstForm.controls.email.hasError('email')
      ? 'No es un email válido'
      : '';
  }

  // Control del Formulario si no es Titular de la Cuenta:
  //Error para email:
  getErrorEmailMessage() {
    return this.fifthForm.controls.ownerEmail.hasError('email')
      ? 'No es un email válido'
      : '';
  }

  back() {
    this.modalCtrl.dismiss();
  }

  // Nos trae datos de localidad gracias al CP:
  async setGeoData(event: string, type?: string) {
    if (event.length === 5) {
      const data: any = await this.bds.getGeodata(event);
      if (data.ok) {
        if (type === 'owner') {
          this.fifthForm.value.ownerCountry = '';
          this.fifthForm.value.ownerCa = '';
          this.fifthForm.value.ownerProvince = '';
          this.fifthForm.value.ownerLocation = '';
          this.fifthForm.patchValue({
            ownerCountry: data.data[0].country.name,
            ownerCa: data.data[0].geodata_1.name,
            ownerProvince: data.data[0].geodata_2.name,
            ownerLocation: data.data[0].name,
          });
        } else if (!type) {
          this.thirdForm.value.country = '';
          this.thirdForm.value.ca = '';
          this.thirdForm.value.province = '';
          this.thirdForm.value.location = '';
          this.thirdForm.patchValue({
            country: data.data[0].country.name,
            ca: data.data[0].geodata_1.name,
            province: data.data[0].geodata_2.name,
            location: data.data[0].name,
          });
          this.local_data = {
            address: this.thirdForm.value.address,
            cp: this.thirdForm.value.cp,
            country: data.data[0].country.name,
            ca: data.data[0].geodata_1.name,
            province: data.data[0].geodata_2.name,
            location: data.data[0].name,
          };
        }
      }
    } else {

      if (type === 'owner') {
        this.fifthForm.value.ownerCountry = '';
        this.fifthForm.value.ownerCa = '';
        this.fifthForm.value.ownerProvince = '';
        this.fifthForm.value.ownerLocation = '';
      } else if (!type) {
        this.thirdForm.value.country = '';
        this.thirdForm.value.ca = '';
        this.thirdForm.value.province = '';
        this.thirdForm.value.location = '';
      }
    }
  }

  // Upload Document Functions
  selectFile1(event: any) {
    this.doc1 = event.target.files[0];
    this.doc1Name = event.target.files[0].name;
    const reader = new FileReader();
    const extension = this.doc1.name.substring(this.doc1.name.lastIndexOf('.'));

    if (event) {
      this.exist1 = true;
    }

    if (extension.includes('.jp' || '.png' || '.heic' || '.bmp')) {
      reader.readAsDataURL(this.doc1);
      reader.onload = (_event) => {
        this.doc1Path = reader.result;
      };
    }
  }

  selectFile2(event: any) {
    this.doc2 = event.target.files[0];
    this.doc2Name = event.target.files[0].name;
    const reader = new FileReader();
    const extension = this.doc2.name.substring(this.doc2.name.lastIndexOf('.'));

    if (event) {
      this.exist2 = true;
    }

    if (extension) {
      reader.readAsDataURL(this.doc2);
      reader.onload = (_event) => {
        this.doc2Path = reader.result;
        switch (extension) {
          case '.pdf':
            this.doc2Path = this.pdfIcon;
            break;
          case '.doc':
            this.doc2Path = this.docIcon;
            break;
          case '.docx':
            this.doc2Path = this.docxIcon;
            break;
          default:
            this.doc2Path = this.anyIcon;
            break;
        }
      };
    }
  }

  async openConfirmationModal() {
    const modal = await this.modalCtrl.create({
      component: ConfirmationNewBrotherModalComponent,
      cssClass: '',
      backdropDismiss: false,
      mode: 'ios',
      componentProps: {
        data: this.enviarDatos()
      }
    });
    await modal.present();
    await modal.onDidDismiss().then((data: any) => {
      if (data.data.end && !data.data.idDebt) {
        this.onFormSubmit();
      }
      if (data.data.end && data.data.idDebt){
        this.onFormSubmit(data.data.idDebt);
      }
    });
  }

  validForm1(identifier: string) {
    const validateIdentifier = this.validateIdentifier(identifier);
    if (validateIdentifier) {
      this.page = 2;
    } else {
      return;
    }
  }

  validForm4(iban: string) {
    const validate = this.fnValidateIBAN(iban);
    if (!validate) {
      return;
    } else {
      this.page = 5;
    }
  }

  validForm5(iban: string, ownerDni: string) {
    const validateIban = this.fnValidateIBAN(iban);
    const validateIdentifier = this.validateIdentifier(ownerDni);
    if (validateIban && validateIdentifier) {
      this.page = 5;
    } else {
      return;
    }
  }

  validForm6(ownerDni: string) {
    const validateIdentifier = this.validateIdentifier(ownerDni);
    if (!validateIdentifier) {
      return;
    } else {
      this.page = 5;
    }
  }

  // Evento DNI Validator
  validaDni(event: string): boolean {
    if (event.length >= 9) {
      if (this.validateIdentifier(event)) {
        this.local_data.identifier = event;
        return true;
      } else {
        return false;
      }
    }
  }

  /** Función que valida un IBAN para añadirlo al método de pago de un hermano */
  fnValidateIBAN(ibancheck: string): boolean {
    if (!IBAN.isValid(ibancheck)) {
      window.alert('El IBAN introducido no es válido. Por favor, compruébelo e introduzca uno correcto (sin espacios).');
      return false;
    } else {
      return true;
    }
  };

  /** Función para validar DNI / NIF / NIE / CIF */
  validateIdentifier(identifier: any) {
    let numero;
    let le;
    let letra;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
    identifier = identifier.toUpperCase();
    if (expresion_regular_dni.test(identifier) === true) {
      numero = identifier.substr(0, identifier.length - 1);
      numero = numero.replace('X', 0);
      numero = numero.replace('Y', 1);
      numero = numero.replace('Z', 2);
      le = identifier.substr(identifier.length - 1, 1);
      numero = numero % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);
      if (letra !== le) {
        window.alert('Nº de documento de identidad erróneo, la letra del NIF no se corresponde');
        return false;
      } else {
        return true;
      }
    } else {
      window.alert('Nº de documento de identidad erróneo, formato no válido');
      return false;
    }
  };

  seeData() {
    this.fourthForm.value.idPaymentMethod = 1;
    const formData = {
      brother: {
        ...this.firstForm.value,
        ...this.secondForm.value,
        ...this.local_data,
      },
      address: {
        ...this.thirdForm.value
      },
      paymentMethod: { ...this.fourthForm.value },
      documents: {
        doc1: this.doc1,
        doc2: this.doc2,
      }

    };

    if (this.fourthForm.value.accountOwner === 1) {
      formData.paymentMethod = {
        ...this.fourthForm.value,
        ...this.fifthForm.value,
      };
    }

    if (this.fourthForm.value.accountOwner === 2) {
      formData.paymentMethod = {
        ...this.fourthForm.value,
        ...this.sixthForm.value,
      };
    }

    console.log(formData);
  }

  // Get the value from Ion-Select
  onSelectChange(event: any) {
    this.accountOwnerSelected = event.target.value;
  };
}


